import React, { useState, useEffect } from "react";
import NeuAnchor from "../NeuAnchor";
import SideNeuCardLink from "./SideNeuCardLink";
import SideColumnFlex from "./SideColumnFlex";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from "../BurgerMenu/DeviceSize";

const SideNeuCard = (props) => {
    const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
    const [equalsCurrentPath, setEqualsCurrentPath] = useState(false);

    const navigated = () => {
        setEqualsCurrentPath(window.location.pathname === props.link && window.location.pathname.indexOf(props.link) > -1);
    };

    let history = useHistory();
    useEffect(() => {
        history.listen(navigated);
    }, [history]);

    const getLinkChild = () => {
        return (
            <SideColumnFlex>
                {props.text ? (
                    <div className="flex-1 flex flex-row justify-items-center" style={{ background: equalsCurrentPath ? "#ecf0f3" : "#fff" }}>
                        <p
                            style={{
                                color: equalsCurrentPath ? "#65b1c7" : "#525C65",
                                background: equalsCurrentPath ? "#ecf0f3" : "#fff",
                                fontSize: isMobile ? "22px" : "24px",
                            }}
                            className="py-2 flex-1 flex md:text-2xl lg:text-2xl xl:text-2xl font-bold w-auto items-center content-center justify-center">
                            {props.text}
                        </p>
                        <p style={{ width: "10px", background: equalsCurrentPath ? "#65b1c7" : "#fff" }}></p>
                    </div>
                ) : (
                    <></>
                )}
                {props.children}
            </SideColumnFlex>
        );
    };

    return props.link?.indexOf("http") > -1 ? (
        <NeuAnchor link={props.link} className={props.className}>
            {getLinkChild()}
        </NeuAnchor>
    ) : (
        <SideNeuCardLink link={props.link} className={props.className} onClick={props.onClick}>
            {getLinkChild()}
        </SideNeuCardLink>
    );
};

export default SideNeuCard;
