import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import MaterialIcon from "../../Components/MaterialIcon";
import { HubCore } from "../../api";

const getRating = (rating) => {
    let value = rating ?? 0;
    const rate = [1, 2, 3, 4, 5];
    return (
        <span>
            {rate.map((index) => (
                <MaterialIcon icon="star" className={index <= value ? "text-yellow-400" : "text-gray-300"} key={index} />
            ))}
        </span>
    );
};

const ResponsesView = () => {
    const [responses, setResponses] = useState([]);
    const [stats, setStats] = useState(null);
    const [ratings, setRatings] = useState([]);

    const fetchResponses = async (clearList = false) => {
        let url = "https://questionnairebe.morabaaapps.com/api/v1/responses/";
        if (!clearList && responses.length % 25 > 0) return;
        if (!clearList) url += `?offset=${responses.length}`;
        var apiResponse = await HubCore.get(url);
        if (clearList) setResponses(apiResponse.data);
        else setResponses([...apiResponse.data, ...responses]);
    };

    const fetchStats = () => HubCore.get("https://questionnairebe.morabaaapps.com/api/v1/responses/stats/").then((response) => setStats(response.data));
    const fetchRatings = () =>
        HubCore.get("https://questionnairebe.morabaaapps.com/api/v1/responses/stats/ratings/").then((response) => setRatings(response.data));
    const fetchTicketsRatings = () => HubCore.get("https://tickets.morabaaapps.com/api/v1/ratings/all").then((response) => setRatings(response.data));

    const loadMore = () => fetchResponses(false);

    const deleteResponse = (id) => {
        HubCore.delete(`https://questionnairebe.morabaaapps.com/api/v1/responses/${id}`).then(() => setResponses(responses.filter((y) => y.id !== id)));
    };

    useEffect(() => {
        fetchStats();
        fetchTicketsRatings();
        fetchResponses();
    }, []);

    return (
        <div className="flex flex-col">
            <table className="mt-4">
                <thead>
                    <tr className="border border-gray-300 border-collapse">
                        <th className="border border-gray-400 p-2 font-bold">إسم الوكيل</th>
                        {Object.keys(ratings).map((x) => (
                            <th key={x} className="border border-gray-400 p-2 font-bold">
                                {x}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border border-gray-400 p-2 text-center">التقييم</td>
                        {Object.keys(ratings).map((x) => (
                            <th key={x} className="border border-gray-400 p-2 font-bold">
                                <p>{getRating(ratings[x])}</p>
                            </th>
                        ))}
                    </tr>
                </tbody>
            </table>
            {stats && (
                <div className="flex flex-row mt-8 mb-4">
                    <p className="ml-2">عدد النتائج الكلي: {stats.totalResponses}</p>
                    {Object.keys(stats.questionStatistics[2].countByChoice).map((x) => (
                        <p className="ml-2" key={x}>
                            {x}: {stats.questionStatistics[2].countByChoice[x]}
                        </p>
                    ))}
                </div>
            )}
            <table>
                <thead>
                    <tr className="border border-gray-300 border-collapse">
                        <th className="border border-gray-400 p-2 font-bold">ت</th>
                        <th className="border border-gray-400 p-2 font-bold">التاريخ</th>
                        <th className="border border-gray-400 p-2 font-bold">الإسم</th>
                        <th className="border border-gray-400 p-2 font-bold">الإسم التجاري</th>
                        <th className="border border-gray-400 p-2 font-bold">العنوان</th>
                        <th className="border border-gray-400 p-2 font-bold">رقم الهاتف</th>
                        <th className="border border-gray-400 p-2 font-bold">كيف تعرفت على الشركة</th>
                        <th className="border border-gray-400 p-2 font-bold">رأيك بالخدمات المقدمة من الشركة</th>
                        <th className="border border-gray-400 p-2 font-bold">رأيك بالموظف</th>
                        <th className="border border-gray-400 p-2 font-bold">الملاحظة</th>
                        <th className="border border-gray-400 p-2 font-bold">إسم الوكيل</th>
                        <th className="border border-gray-400 p-2 font-bold">حذف</th>
                    </tr>
                </thead>
                <tbody>
                    {responses.map((x, i) => (
                        <tr key={x.id}>
                            <td className="border border-gray-400 p-2">
                                <p className="text-right">{i + 1}</p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-right">
                                    <Moment locale="ar" fromNow>
                                        {x.respondedAt}
                                    </Moment>
                                </p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-right">{x.fullName}</p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-right">{x.businessTitle}</p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-right">{x.address}</p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-right">{x.phoneNumber}</p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-right">{x.responses[2]?.choice}</p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-right">{x.responses[1]?.choice}</p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-right">{x.responses[0]?.choice}</p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-right">{x.notes}</p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-right">{x.staff || x.agent}</p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-center text-red-700 cursor-pointer" onClick={() => deleteResponse(x.id)}>
                                    <MaterialIcon icon="delete" />
                                </p>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ResponsesView;
