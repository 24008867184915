import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { HubCore } from "../../api";
import GridComponent from "../../Components/GridComponent";

const SubscriptionDetailsView = () => {
    const [subs, setSubs] = useState([]);
    let params = useParams();

    const fetch = () => {
        let data = [params.ownerId];

        HubCore.post(`/subscriptions/ofbusinesses/${params.solutionId}/`, data).then((response) => {
            setSubs(response.data);
        });
    };

    useEffect(fetch, []);

    return (
        <GridComponent>
            {subs &&
                subs.map((sub) => (
                    <div
                        key={sub.subscription.id}
                        className="border border-gray-300 hover:border-blue-900 p-4 rounded cursor-pointer shadow-lg hover:shadow-md">
                        <p className="font-bold">{Object.values(sub.business)[0]}</p>
                        <p className="mt-2">{sub.tier.title}</p>
                        <p className="mt-3 font-bold text-gray-600">
                            {sub.tier.limitations.map((limitation) => (
                                <span key={limitation.id}>
                                    {limitation.key} {limitation.decimalValue}
                                    <br />
                                </span>
                            ))}
                        </p>
                    </div>
                ))}
        </GridComponent>
    );
};

export default SubscriptionDetailsView;
