import React from "react";
import moment from "moment";
import RowFlex from "./RowFlex";
import { Link } from "react-router-dom";
import MaterialIcon from "./MaterialIcon";

const getRating = (rating) => {
    let value = rating?.value ?? 0;
    const rate = [1, 2, 3, 4, 5];
    return (
        <span>
            {rate.map((index) => (
                <MaterialIcon icon="star" className={index <= value ? "text-yellow-400" : "text-gray-300"} key={index} />
            ))}
        </span>
    );
};

const TicketCard = ({ ticket }) => {
    const State = ({ state }) => <p className={`${getStateTextColor(state)} font-bold`}>{getStateText(state)}</p>;

    const getStateText = (state) => {
        if (!state) return "مفتوحة";
        let stateValue = state.state;
        return stateValue === 0 ? "مفتوحة" : "مغلقة";
    };

    const getStateTextColor = (state) => (state ? (state.state === 0 ? "text-green-700" : "text-red-700") : "text-green-700");

    return (
        <Link to={`/tickets/${ticket.ticket.id}`}>
            <div className="bg-gray-100 shadow-xl hover:shadow-lg rounded p-4">
                <RowFlex className="justify-between">
                    <p className="font-semibold">{ticket.agentName}</p>
                    <p className="font-semibold">{ticket.ticket.title}</p>
                    <State state={ticket.state} />
                </RowFlex>
                <p className="text-gray-600 rounded p-2 mt-4">{ticket.businessTitle}</p>
                <p className="bg-gray-600 text-gray-100 h-28 rounded p-2 mb-4 truncate overflow-ellipsis">{ticket.ticket.text}</p>
                <RowFlex className="justify-between ">
                    <p>{getRating(ticket.rating)}</p>
                    <p>{moment(ticket.ticket.openedAt).fromNow()}</p>
                </RowFlex>
            </div>
        </Link>
    );
};

export default TicketCard;
