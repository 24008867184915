import React, { useEffect, useState } from "react";
import { HubCore } from "../../api";
import FormInput from "../../Components/FormInput";
import GridComponent from "../../Components/GridComponent";
import LoaderComponent from "../../Components/LoaderComponent";
import NeuCard from "../../Components/NeuCard";
import NeuCardLink from "../../Components/NeuCardLink";

const SolutionsView = () => {
    const [solutions, setSolutions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [tiers, setTiers] = useState([]);
    const [limitations, setLimitations] = useState([]);
    const [selectedSolution, setSelectedSolution] = useState("");
    const [selectedTier, setSelectedTier] = useState("");
    const [selectedTierTitle, setSelectedTierTitle] = useState("");
    const [selectedTierPrice, setSelectedTierPrice] = useState("");
    const [subscribers, setSubscribers] = useState([]);

    const fetchSolutions = () =>
        HubCore.get("/solutions")
            .then((response) => setSolutions(response.data))
            .finally(() => setIsLoading(false));

    const fetchTiers = () => {
        if (!selectedSolution) setTiers([]);
        else HubCore.get(`/solutions/${selectedSolution}/tiers`).then((response) => setTiers(response.data));
    };

    const fetchLimitations = () => {
        if (!selectedTier || !selectedSolution) setLimitations([]);
        else HubCore.get(`/solutions/${selectedSolution}/tiers/${selectedTier}/limitations`).then((response) => setLimitations(response.data));
    };

    const selectedTierChanged = (tier) => {
        setSelectedTier(tier.id);
        setSelectedTierTitle(tier.title);
        setSelectedTierPrice(tier.monthlyPrice);
    };

    const updateTier = () =>
        HubCore.patch(`/solutions/${selectedSolution}/tiers/${selectedTier}`, { title: selectedTierTitle, monthlyPrice: selectedTierPrice }).then(() =>
            fetchTiers()
        );

    const deleteTier = (id) =>
        HubCore.delete(`/solutions/${selectedSolution}/tiers/${id}`)
            .then(() => {
                let tiersToSet = [];
                for (let i = 0; i < tiers.length; i++) {
                    let tier = tiers[i];
                    tier.isDeleted = tier.tier.id === id;
                    tiersToSet.push(tier);
                }
                setTiers(tiersToSet);
            })
            .catch((e) => {
                alert(e.response.data.detail);
            });

    const getSubscribers = (id) => HubCore.get(`/solutions/${selectedSolution}/tiers/${id}/subscribers`).then((response) => setSubscribers(response.data));

    useEffect(() => fetchSolutions(), []);
    useEffect(() => fetchTiers(), [selectedSolution]);
    useEffect(() => fetchLimitations(), [selectedTier]);

    return isLoading ? (
        <LoaderComponent />
    ) : solutions ? (
        <div className="flex flex-col">
            <GridComponent>
                {solutions.map((x) => (
                    <NeuCard
                        key={x.id}
                        className={`p-4 ${x.id === selectedSolution ? "border-b-2 border-green-500" : ""}`}
                        onClick={() => setSelectedSolution(x.id)}>
                        <p className="font-bold text-xl text-gray-700">{x.title}</p>
                    </NeuCard>
                ))}
            </GridComponent>
            <GridComponent>
                {tiers &&
                    tiers.map((x) => (
                        <NeuCard key={x.tier.id} className={`p-4 ${x.tier.id === selectedSolution ? "border-b-2 border-green-500" : ""}`}>
                            <div className="flex flex-col">
                                <p className="font-bold text-xl text-gray-700 mb-2" onClick={() => selectedTierChanged(x.tier)}>
                                    <span className={`${x.tier.isDeleted ? "line-through" : ""}`}>{x.tier.title}</span>
                                    <br />
                                    <span className="text-green-700 text-base">${x.tier.monthlyPrice}</span>
                                </p>
                                {x.tier.isDeleted ? (
                                    <></>
                                ) : (
                                    <button className="px-4 py-2 mb-2 rounded bg-red-700 text-gray-50 font-bold" onClick={() => deleteTier(x.tier.id)}>
                                        حذف
                                    </button>
                                )}
                                <button className="px-4 py-2 rounded bg-green-700 text-gray-50 font-bold" onClick={() => getSubscribers(x.tier.id)}>
                                    المشتركون
                                </button>
                            </div>
                        </NeuCard>
                    ))}
            </GridComponent>
            {selectedTier && (
                <div className="flex flex-col mb-12">
                    <FormInput value={selectedTierTitle} valueChanged={(e) => setSelectedTierTitle(e.target.value)} placeholder="tier title" />
                    <FormInput
                        value={selectedTierPrice}
                        type="number"
                        step={0.01}
                        min={0}
                        valueChanged={(e) => setSelectedTierPrice(e.target.value)}
                        placeholder="tier monthly price"
                    />
                    <button className="self-start mt-2 px-4 py-2 rounded bg-green-700 font-bold text-gray-50" onClick={() => updateTier()}>
                        حفظ
                    </button>
                </div>
            )}
            <GridComponent>
                {limitations &&
                    limitations.map((x) => (
                        <NeuCard key={x.id} className="p-4" onClick={() => setSelectedTier(x.id)}>
                            <p className="font-bold text-xl text-gray-700" style={{ lineBreak: "anywhere" }}>
                                {x.key}
                                <br />
                                <span className="text-blue-700 text-base">{x.decimalValue}</span>
                                <br />
                                <span className="text-blue-700 text-base">{x.dateTimeValue}</span>
                            </p>
                        </NeuCard>
                    ))}
            </GridComponent>
            {subscribers && (
                <GridComponent>
                    {Object.keys(subscribers).map((x) => (
                        <NeuCardLink className="p-4" key={x} link={`/businesses/${x}`}>
                            {subscribers[x]}
                        </NeuCardLink>
                    ))}
                </GridComponent>
            )}
        </div>
    ) : (
        <></>
    );
};

export default SolutionsView;
