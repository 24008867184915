import { HubCore } from "../../api";
import React, { useState } from "react";
import GridComponent from "../../Components/GridComponent";
import NeuCard from "../../Components/NeuCard";
import FormInput from "../../Components/FormInput";
import ColumnFlex from "../../Components/ColumnFlex";
import FormSubmit from "../../Components/FormSubmit";
import LoaderComponent from "../../Components/LoaderComponent";

const CardGenView = () => {
    const [count, setCount] = useState(0);
    const [credits, setCredits] = useState(0);
    const [loading, setLoading] = useState(false);
    const [cards, setCards] = useState([]);

    const create = (e) => {
        e.preventDefault();
        if (loading) return false;
        setLoading(true);
        let data = {
            count: count,
            credits: credits,
        };
        HubCore.post("/cards/", data)
            .then((response) => {
                setCards(response.data);
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
        return false;
    };

    return loading ? (
        <LoaderComponent />
    ) : (
        <ColumnFlex>
            <form onSubmit={create}>
                <ColumnFlex>
                    <FormInput
                        name="count"
                        placeholder="عدد البطاقات"
                        type="number"
                        value={count}
                        valueChanged={(e) => setCount(e.target.value)}
                        required="required"
                    />
                    <FormInput
                        name="credits"
                        placeholder="المبلغ على البطاقة"
                        type="number"
                        value={credits}
                        valueChanged={(e) => setCredits(e.target.value)}
                        required="required"
                    />
                    <FormSubmit value="انشاء" />
                </ColumnFlex>
            </form>
            {cards ? (
                <GridComponent>
                    {cards.map((card) => (
                        <NeuCard link="#" key={card.serialNumber} text={card.secret}>
                            <p className="font-bold text-green-500 text-center text-lg mb-2">${card.credits}</p>
                        </NeuCard>
                    ))}
                </GridComponent>
            ) : (
                <></>
            )}
        </ColumnFlex>
    );
};

export default CardGenView;
