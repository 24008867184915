import React from "react";
import ColumnFlex from "./ColumnFlex";
import FormSubmit from "./FormSubmit";
import RowFlex from "./RowFlex";

const Form = (props) => {
    const submit = (e) => {
        e.preventDefault();
        props.onSubmit();
        return false;
    };

    return (
        <form onSubmit={submit} className={props.className}>
            <ColumnFlex>
                {props.children}
                {props.footer ? (
                    <RowFlex>
                        <FormSubmit value={props.submitText} />
                        {props.footer}
                    </RowFlex>
                ) : (
                    <FormSubmit value={props.submitText} />
                )}
            </ColumnFlex>
        </form>
    );
};

export default Form;
