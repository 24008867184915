import React, { useEffect, useState } from "react";
import { HubCore } from "../../api";

const Keys = {
    "salereports.accounts": "حسابات",
    "salereports.nets": "ملخص يومي",
    "salereports.transactions": "حركات الحسابات",
    "salereports.businesstransactions": "حركات الملخص اليومي",
    "items.items": "المواد",
    "onlinedbbackup.backups": "اونلاين بكب",
    "saturn.invoices": "قوائم البيع",
};

let timeout = null;

const UploadStatsView = () => {
    const [query, setQuery] = useState({
        datelte: new Date().toISOString().split("T")[0],
        dategte: new Date().toISOString().split("T")[0],
        key: "",
        businessTitle: "",
    });
    const [data, setData] = useState([]);

    const fetchData = async () => {
        const response = await HubCore.get("/stats", {
            params: query,
        });
        setData(Object.values(response.data));
    };

    useEffect(() => {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            fetchData();
        }, 500);
    }, [query]);

    return (
        <div className="flex flex-col gap-4">
            <div className="grid gap-2 grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
                <input
                    type="text"
                    className="px-4 py-2 rounded"
                    placeholder="بحث بواسطة اسم الشركة"
                    value={query.businessTitle}
                    onChange={(e) => setQuery({ ...query, businessTitle: e.target.value })}
                />
                <select className="px-4 py-2 rounded" value={query.key} onChange={(e) => setQuery({ ...query, key: e.target.value })}>
                    <option value="">الكل</option>
                    {Object.keys(Keys).map((x) => (
                        <option key={x} value={x}>
                            {Keys[x]}
                        </option>
                    ))}
                </select>
                <input type="date" className="px-4 py-2 rounded" value={query.dategte} onChange={(e) => setQuery({ ...query, dategte: e.target.value })} />
                <input type="date" className="px-4 py-2 rounded" value={query.datelte} onChange={(e) => setQuery({ ...query, datelte: e.target.value })} />
            </div>
            <table>
                <thead>
                    <tr className="border-b-2 border-black mb-2 text-right">
                        <th>الشركة</th>
                        <th>العملية</th>
                        <th>العدد</th>
                        <th>التاريخ</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((x) =>
                        x.map((s) => (
                            <tr key={s.stat.key}>
                                <td className="px-4 pb-2 border-b border-gray-700">{s.businessTitle}</td>
                                <td className="px-4 pb-2 border-b border-gray-700">{Keys[s.stat.key] ?? "-"}</td>
                                <td className="px-4 pb-2 border-b border-gray-700">{s.stat.count}</td>
                                <td className="px-4 pb-2 border-b border-gray-700">{s.stat.date.split("T")[0]}</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default UploadStatsView;
