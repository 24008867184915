import React, { useEffect, useState } from "react";
import { HubCore } from "../../api";
import ColumnFlex from "../../Components/ColumnFlex";
import RowFlex from "../../Components/RowFlex";
import Moment from "react-moment";

const BusinessSettingsLogsView = () => {
    const [logs, setLogs] = useState([]);
    const [dtFrom, setDtFrom] = useState(new Date().toISOString().split("T")[0]);
    const [dtTo, setDtTo] = useState(new Date().toISOString().split("T")[0]);
    const [canLoadMore, setCanLoadMore] = useState(true);

    const fetchLogs = (clear = false) => {
        let url = `https://businesssettings.morabaaapps.com/api/v1/logs/?offset=${clear === true ? 0 : logs.length}&`;
        if (dtFrom) url += `from=${dtFrom}&`;
        if (dtTo) url += `to=${dtTo}&`;
        HubCore.get(url).then((response) => {
            setCanLoadMore(response.data.length >= 25);
            if (clear === true) setLogs(response.data);
            else setLogs([...logs, ...response.data]);
        });
    };

    useEffect(() => fetchLogs(true), []);

    return (
        <ColumnFlex>
            <RowFlex className="mb-4">
                <ColumnFlex className="mr-2">
                    <p className="font-bold text-lg text-gray-600 flex-1">من تاريخ</p>
                    <input type="date" value={dtFrom} onChange={(e) => setDtFrom(e.target.value)} className="px-4 py-2 mt-2" />
                </ColumnFlex>
                <ColumnFlex className="mr-2">
                    <p className="font-bold text-lg text-gray-600 flex-1">إلى تاريخ</p>
                    <input type="date" value={dtTo} onChange={(e) => setDtTo(e.target.value)} className="px-4 py-2 mt-2" />
                </ColumnFlex>
                <button className="col-span-full px-4 py-2 rounded bg-green-700 text-gray-50 font-bold text-xl self-end mr-2" onClick={() => fetchLogs(true)}>
                    بحث
                </button>
            </RowFlex>
            <table>
                <thead>
                    <tr className="border border-gray-300 border-collapse">
                        <th className="border border-gray-400 p-2 font-bold">ت</th>
                        <th className="border border-gray-400 p-2 font-bold">الإعداد</th>
                        <th className="border border-gray-400 p-2 font-bold">وقت التغيير</th>
                        <th className="border border-gray-400 p-2 font-bold">الشركة</th>
                        <th className="border border-gray-400 p-2 font-bold">الموظف</th>
                        <th className="border border-gray-400 p-2 font-bold">Old String</th>
                        <th className="border border-gray-400 p-2 font-bold">String</th>
                        <th className="border border-gray-400 p-2 font-bold">Old Decimal</th>
                        <th className="border border-gray-400 p-2 font-bold">Decimal</th>
                        <th className="border border-gray-400 p-2 font-bold">Old Boolean</th>
                        <th className="border border-gray-400 p-2 font-bold">Boolean</th>
                    </tr>
                </thead>
                <tbody>
                    {logs.map((x, i) => (
                        <tr key={x.id} className="border border-gray-300 border-collapse">
                            <td className="border border-gray-400 p-2">
                                <p className="text-right">{i + 1}</p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-right">{x.title}</p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-center">
                                    <Moment fromNow>{x.createdAt}</Moment>
                                </p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-center">{x.businessTitle}</p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-center">{x.staffFullName}</p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-center">{x.previousStringValue}</p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-center">{x.stringValue}</p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-center">{x.previousDecimalValue}</p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-center">{x.decimalValue}</p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-center">{x.booleanValue === true ? "✅" : "❌"}</p>
                            </td>
                            <td className="border border-gray-400 p-2">
                                <p className="text-center">{x.previousBooleanValue === true ? "✅" : "❌"}</p>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {canLoadMore && (
                <button className="px-4 py-2 bg-green-500 text-gray-50 rounded mt-4" onClick={() => fetchLogs(false)}>
                    Load more
                </button>
            )}
        </ColumnFlex>
    );
};

export default BusinessSettingsLogsView;
