import React from "react";
import { Link } from "react-router-dom";
import ColumnFlex from "./ColumnFlex";
import LinkCard from "./LinkCard";
import MaterialIcon from "./MaterialIcon";
import RowFlex from "./RowFlex";

const StaffCard = ({ staff, ...props }) => {
    return (
        <div className="neu-card p-3" key={staff.id}>
            <ColumnFlex className="justify-between">
                <p>
                    <span className="font-light">{staff.fullName}</span>
                    <br />
                    <span className="font-bold">{staff.phoneNumber}</span>
                    <br />
                    <span className="font-semibold">{staff.isSuperStaff ? "ادمن" : "موظف"}</span>
                </p>
                <RowFlex className="mt-4">
                    <LinkCard to={`/staff/${staff.id}/edit`} data={staff}>
                        <MaterialIcon icon="edit" className="text-blue-400 hover:text-blue-500 cursor-pointer text-3xl" />
                    </LinkCard>
                    <div className="mx-6">
                        <Link to={`/staff/${staff.id}`}>
                            <MaterialIcon icon="manage_accounts" className="text-green-500 hover:text-green-600 text-3xl" />
                        </Link>
                    </div>
                    {!staff.isSuperStaff && (
                        <div onClick={() => props.deleteStaff(staff)}>
                            <MaterialIcon icon="delete" className="text-red-500 hover:text-red-600 cursor-pointer text-3xl" />
                        </div>
                    )}
                </RowFlex>
            </ColumnFlex>
        </div>
    );
};

export default StaffCard;
