import FormInput from "./FormInput";
import React, { useState } from "react";
import NewUser from "../Components/NewUser";

const NewAgent = ({ onSuccess, onFailure }) => {
    const [province, setProvince] = useState("النجف الاشرف");

    return (
        <NewUser onSuccess={onSuccess} url="agents" title="اضافة وكيل" additionalData={{ province: province }}>
            <FormInput
                name="province"
                placeholder="المحافظة"
                value={province}
                valueChanged={(e) => setProvince(e.target.value)}
                required={"required"}
            />
        </NewUser>
    );
};

export default NewAgent;
