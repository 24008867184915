import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { HubCore } from "../../api";
import GridComponent from "../../Components/GridComponent";
import LoaderComponent from "../../Components/LoaderComponent";
import MaterialIcon from "../../Components/MaterialIcon";

const BusinessesSolutionsView = (props) => {
    const [businessSolutions, setBusinessSolutions] = useState();
    const [solutions, setSolutions] = useState();
    const [solutionsIds, setSolutionsIds] = useState([]);
    const [isLoadeing, setIsLoadeing] = useState(true);
    const categoryId = useParams().id;
    const { data } = props.location;
    const fetchBusinessesSolutions = () => {
        HubCore.get(`/businesses/categories/${categoryId}/solutions`)
            .then((result) => {
                setBusinessSolutions(result.data);
                fetchSolutions(result.data);
            })
            .finally(() => setIsLoadeing(false));
    };

    useEffect(fetchBusinessesSolutions, []);
    const fetchSolutions = (owneredSolution) => {
        HubCore.get(`/solutions`)
            .then((result) => {
                let felteredSolution = [];
                result.data.forEach((element) => {
                    let addIt = true;
                    owneredSolution.forEach((element2) => {
                        if (element2.id === element.id) {
                            addIt = false;
                            return;
                        }
                    });
                    if (addIt) felteredSolution.push(element);
                });
                setSolutions(felteredSolution);
            })
            .finally(() => setIsLoadeing(false));
    };

    const BusinessSolutionsCard = ({ solution, add, remove }) => {
        return (
            <div className="bg-gray-50 rounded p-2 shadow-lg">
                <p>{solution.title}</p>
                <p>{solution.description}</p>
                {add && (
                    <button onClick={add} className="bg-green-500 w-full mt-2 flex-1">
                        <MaterialIcon icon="add" className="text-white text-2xl" />
                    </button>
                )}
                {remove && (
                    <button onClick={remove} className="bg-red-500 w-7 h-7 text-2xl rounded-full">
                        <MaterialIcon icon="remove" className="text-white text-xl" />
                    </button>
                )}
            </div>
        );
    };

    const addSolution = (solution) => {
        setBusinessSolutions([...businessSolutions, solution]);
        setSolutions(solutions.filter((sol) => sol.id !== solution.id));
        setSolutionsIds([...solutionsIds, solution.id]);
    };
    const removeSolution = (solution) => {
        setBusinessSolutions(businessSolutions.filter((element) => element.id !== solution.id));
        setSolutions([...solutions, solution]);
    };

    const patchSolution = () => {
        HubCore.patch(`businesses/categories/${categoryId}/solutions`, { solutionsIds }).then(() => {
            alert("Done");
            setSolutionsIds([]);
        });
    };

    return isLoadeing ? (
        <LoaderComponent />
    ) : (
        <>
            <p className="font-bold text-2xl mb-4">{data}</p>
            <p className="font-bold text-xl mb-1">الخدمات المتوفرة</p>
            <hr className=" bg-red-900 mb-2" />
            {solutionsIds.length > 0 && (
                <button onClick={patchSolution} className="bg-green-500 p-1 text-gray-50 mb-2">
                    حفظ
                </button>
            )}
            <GridComponent>
                {businessSolutions &&
                    businessSolutions.map((solution) => (
                        <BusinessSolutionsCard remove={() => removeSolution(solution)} solution={solution} key={solution.id} />
                    ))}
            </GridComponent>
            <p className="font-bold text-xl mb-1">اضافة خدمة</p>
            <hr className=" bg-red-900 mb-2" />
            {solutionsIds.length > 0 && (
                <button onClick={patchSolution} className="bg-green-500 p-1 text-gray-50 mb-2">
                    حفظ
                </button>
            )}
            <GridComponent>
                {solutions && solutions.map((solution) => <BusinessSolutionsCard add={() => addSolution(solution)} solution={solution} key={solution.id} />)}
            </GridComponent>
        </>
    );
};

export default BusinessesSolutionsView;
