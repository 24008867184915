import { HubCore } from "../../api";
import { useParams } from "react-router";
import DropZone from "../../Components/DropZone";
import React, { useState, useEffect } from "react";
import ColumnFlex from "../../Components/ColumnFlex";
import ProgressRing from "../../Components/ProgressRing";
import LoaderComponent from "../../Components/LoaderComponent";
import { ReactComponent as UploadIcon } from "../../Assets/Images/upload.svg";

const GuestUploadView = () => {
    const [newFiles, setNewFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isUploadDone, setIsUploadDone] = useState(false);
    const [canUpload, setCanUpload] = useState(true);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadSpeed, setUploadSpeed] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [url, setUrl] = useState("");
    let params = useParams();

    const filesChanged = (files) => {
        setNewFiles(files);
    };

    const uploadFiles = () => {
        if (!newFiles) return;

        let started = new Date();

        const axiosConfig = {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                if (percentCompleted !== uploadProgress) setUploadProgress(percentCompleted);
                let elapsed = new Date() - started;
                setUploadSpeed(Math.round(progressEvent.loaded / (elapsed / 1000) / 1024));
            },
        };

        setIsUploading(true);
        let formData = new FormData();
        for (let i = 0; i < newFiles.length; i++) {
            formData.append("files", newFiles[i], newFiles[i].name);
        }
        formData.append("groupId", "3d3d32bd-6594-485e-97c4-e4919d8d7459");

        HubCore.post(`/files?token=${params.token}`, formData, axiosConfig)
            .then((response) => {
                setIsUploadDone(true);
                setUrl(`https://hubcore.morabaaapps.com${response.data[0].filePath}`);
            })
            .finally(() => {
                setNewFiles([]);
                setIsUploading(false);
                setUploadProgress(0);
                setUploadSpeed(0);
            });
    };

    const checkTokenValidity = () => {
        HubCore.get(`/files/checktoken/${params.token}`)
            .then(() => setCanUpload(true))
            .catch(() => setCanUpload(false))
            .finally(() => setIsLoading(false));
    };

    const copyToClipboard = () => {
        const el = document.createElement("textarea");
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
    };

    useEffect(checkTokenValidity, []);

    return isLoading || isUploading ? (
        <ColumnFlex>
            {isUploading ? (
                <>
                    <ProgressRing radius={128} stroke={5} progress={uploadProgress} additionalText={`${uploadSpeed} KB/s`} className="mx-auto" />
                </>
            ) : (
                <LoaderComponent />
            )}
        </ColumnFlex>
    ) : (
        <ColumnFlex>
            {canUpload ? (
                isUploadDone ? (
                    <>
                        <p className="text-3xl font-bold center text-center">
                            تم الرفع
                            <br />
                            <br />
                            <a href={url} target="_blank" className="text-2xl underline text-blue-400">
                                {url}
                            </a>
                        </p>

                        <button
                            onClick={copyToClipboard}
                            className="ml-2 mt-2 px-4 py-2 bg-gray-50 text-purple-800 rounded shadow-md hover:shadow-lg hover:bg-yellow-300 hover:font-bold hover:flex-grow">
                            نسح الرابط
                        </button>
                    </>
                ) : (
                    <>
                        <DropZone filesDroped={filesChanged} />
                        {newFiles.length > 0 && (
                            <>
                                <button
                                    onClick={uploadFiles}
                                    className="h-64 w-64 shadow-lg hover:shadow-xl bg-gray-100 hover:bg-purple-100 rounded-full mt-8 mx-auto">
                                    <UploadIcon className="h-32 w-32 mx-auto" />
                                </button>
                            </>
                        )}
                    </>
                )
            ) : (
                <p className="text-3xl font-bold center text-center">الرابط غير صحيح</p>
            )}
        </ColumnFlex>
    );
};

export default GuestUploadView;
