import { HubCore } from "../../api";
import NeuCard from "../../Components/NeuCard";
import React, { useEffect, useState } from "react";
import LoaderComponent from "../../Components/LoaderComponent";
import GridComponent from "../../Components/GridComponent";
import ColumnFlex from "../../Components/ColumnFlex";
import RowFlex from "../../Components/RowFlex";
import Form from "../../Components/FormSearch";
import { Link } from "react-router-dom";

let timeout = null;

const BusinessesView = ({ ownerId = "" }) => {
    const [businesses, setBusinesses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [updateChannels, setUpdateChannels] = useState([]);
    const [updateChannel, setUpdateChannel] = useState("");

    const search = () => fetchBusinesses();

    const fetchBusinesses = (clear = true) => {
        let url = "/businesses/?";
        if (ownerId) url += `&owner=${ownerId}`;
        if (searchTerm) url += `&title=${searchTerm}`;
        if (updateChannel) url += `&updateChannelKey=${updateChannel}`;
        if (!clear) url += `&offset=${businesses.length}`;

        HubCore.get(url).then((response) => {
            let b = response.data.filter((x) => !!x);
            if (clear) setBusinesses(b);
            else setBusinesses((x) => [...x, ...b]);
            setLoading(false);
        });
    };

    const fetchUpdateChannels = () => {
        HubCore.get(`/update-channels/`).then((response) => setUpdateChannels(response.data));
    };

    useEffect(() => {
        fetchBusinesses();
        fetchUpdateChannels();
    }, []);

    useEffect(() => {
        if (!!timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            fetchBusinesses();
        }, 500);
    }, [searchTerm, updateChannel]);

    return (
        <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
            {loading && (
                <div className="col-span-full">
                    <LoaderComponent />
                </div>
            )}
            <input
                className="col-start-1 col-span-full md:col-span-1 px-4 py-2 rounded-lg"
                placeholder="الإسم"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
            />
            <select value={updateChannel} onChange={(e) => setUpdateChannel(e.target.value)}>
                <option value="">قناة التحديث</option>
                {updateChannels.map((x) => (
                    <option key={x.key} value={x.key}>
                        {x.name}
                    </option>
                ))}
            </select>
            <span className="col-span-full"></span>
            {businesses.map((business) => (
                <NeuCard link={`/businesses/${business.id}`} text={business.title} key={business.id} />
            ))}
            {businesses.length > 0 && businesses.length % 25 === 0 && (
                <button
                    className="col-start-1 px-4 py-2 rounded-lg border-2 border-gray-300/70 bg-green-500 cursor-pointer text-white font-bold"
                    onClick={() => fetchBusinesses(false)}>
                    load more
                </button>
            )}
        </div>
    );
};

export default BusinessesView;
