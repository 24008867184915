import React, { useState, useEffect, useRef } from "react";
import { HubCore } from "../../api";
import FormInput from "../../Components/FormInput";
import Form from "../../Components/Form";
import ColumnFlex from "../../Components/ColumnFlex";
import RowFlex from "../../Components/RowFlex";
import { SketchPicker } from "react-color";

const BusinessSettingsView = () => {
    const [business, setBusiness] = useState(null);
    const [businessPIN, setBusinessPIN] = useState("");
    const [businessSettings, setBusinessSettings] = useState([]);
    const [receivedSettings, setReceivedSettings] = useState([]);
    const [lastUpdate, setLastUpdate] = useState(null);
    const [selectedTab, setSelectedTab] = useState("");
    const [settingSearchTerm, setSettingSearchTerm] = useState("");
    const [logoUrl, setLogoUrl] = useState("");
    const [bannerUrl, setBannerUrl] = useState("");
    const [newLogo, setNewLogo] = useState([]);
    const [newBanner, setNewBanner] = useState([]);

    const uploadLogoRef = useRef(null);
    const uploadBannerRef = useRef(null);

    const settingsRoot = "https://businesssettings.morabaaapps.com/api/v1/settings";

    const fetchBusinessByPIN = () =>
        HubCore.get(`/businesses/bypin/${businessPIN}`)
            .then((response) => setBusiness(response.data))
            .catch((err) => {
                let errorResponse = err.response;
                if (errorResponse.stateCode === 403) alert("لا تملك الصلاحية المطلوبة");
            });

    const fetchBusinessSettings = () => HubCore.get(`${settingsRoot}/ofbusiness/${business.id}`).then((response) => setBusinessSettings(response.data));

    const fetchAllSettings = () =>
        HubCore.get(settingsRoot).then((response) => {
            let allSettingsToSet = [];
            response.data.forEach((x) => {
                let settings = [];
                x.settingResponses.forEach((setting) => {
                    let settingToPush = JSON.parse(JSON.stringify(setting));
                    let businessSetting = businessSettings.find((x) => x.key === setting.key);
                    if (businessSetting) {
                        settingToPush.stringValue = businessSetting.stringValue;
                        settingToPush.booleanValue = businessSetting.booleanValue;
                        settingToPush.decimalValue = businessSetting.decimalValue;
                    }
                    settings.push(settingToPush);
                });
                allSettingsToSet.push({ title: x.title, settingResponses: settings });
            });
            setReceivedSettings(allSettingsToSet);
        });

    const fetchLastUpdate = () =>
        HubCore.get(`${settingsRoot}/ofbusiness/${business.id}/lastupdate`)
            .then((response) => setLastUpdate(response.data))
            .catch(() => {});

    const setImages = () => {
        setLogoUrl(`https://hubcore.morabaaapps.com${business.logoPath}`);
        setBannerUrl(`https://hubcore.morabaaapps.com${business.bannerPath}`);
    };

    const updateSettings = (key, stringValue = "", decimalValue = 0, booleanValue = false) => {
        let toSet = [];
        receivedSettings.forEach((y) => {
            let settingsToSet = [];
            y.settingResponses.forEach((x) => {
                if (x.key === key) {
                    settingsToSet.push({
                        ...x,
                        stringValue: stringValue,
                        decimalValue: decimalValue,
                        booleanValue: booleanValue,
                    });
                } else {
                    settingsToSet.push(x);
                }
            });
            toSet.push({ title: y.title, settingResponses: settingsToSet });
        });
        setReceivedSettings(toSet);
    };

    const saveSettings = () => {
        if (!business) return;
        if (!window.confirm("هل انت متأكد من حفظ الإعدادات وإرسالها إلى الزبون?")) return;
        let data = [];
        receivedSettings.forEach((y) =>
            y.settingResponses.forEach((x) => {
                data.push({
                    ...x,
                    businessId: business.id,
                });
            })
        );
        HubCore.post(settingsRoot, data).then(() => {
            alert("تم الحفظ الإعدادات");
            if ((!newLogo || newLogo.length < 1) && (!newBanner || newBanner.length < 1)) return;
            let data = new FormData();
            if (newLogo && newLogo.length > 0) data.append("Logo", newLogo[0], newLogo[0].name);
            if (newBanner && newBanner.length > 0) data.append("Banner", newBanner[0], newBanner[0].name);
            HubCore.put(`https://hubcore.morabaaapps.com/api/v1/businesses/${business.id}/images`, data).then(() => alert("تم تحديث البنر واللوكو"));
        });
    };

    useEffect(() => {
        if (!newLogo || newLogo.length < 1) return;
        setLogoUrl(URL.createObjectURL(newLogo[0]));
    }, [newLogo]);

    useEffect(() => {
        if (!newBanner || newBanner.length < 1) return;
        setBannerUrl(URL.createObjectURL(newBanner[0]));
    }, [newBanner]);

    useEffect(() => {
        if (!business) return;
        fetchBusinessSettings();
        fetchLastUpdate();
        setImages();
    }, [business]);

    useEffect(() => {
        if (!business) return;
        fetchAllSettings();
    }, [businessSettings]);

    const getSettingsCard = (x) => {
        if (x.key === "ExchangeTransferPassword") console.log(x);
        return (
            <div key={x.key} className="rounded p-4 shadow-lg bg-gray-50">
                <p>{x.title}</p>
                {x.type === "string" && (
                    <input
                        className="mt-2 p-2 border border-gray-200"
                        value={x.stringValue}
                        onChange={(e) => updateSettings(x.key, e.target.value, 0, false)}
                    />
                )}
                {x.type === "decimal" && (
                    <input
                        className="mt-2 p-2 border border-gray-200"
                        value={x.decimalValue}
                        onChange={(e) => updateSettings(x.key, "", parseInt(e.target.value), false)}
                    />
                )}
                {x.type === "boolean" && (
                    <input
                        type="checkbox"
                        className="mt-2 p-2 border border-gray-200"
                        checked={x.booleanValue}
                        onChange={(e) => updateSettings(x.key, "", 0, e.target.checked)}
                    />
                )}
                {x.type === "choice" && (
                    <select
                        className="mt-2 p-2 border border-gray-200"
                        value={x.decimalValue}
                        onChange={(e) => updateSettings(x.key, "", e.target.value, false)}>
                        {Object.keys(x.choices).map((y, i) => (
                            <option value={i}>{y}</option>
                        ))}
                    </select>
                )}
                {x.type === "color" && <SketchPicker color={x.stringValue || "#fafaf9"} onChangeComplete={(c) => updateSettings(x.key, c.hex, 0, false)} />}
            </div>
        );
    };

    return (
        <div className="flex flex-col">
            <Form submitText="بحث" onSubmit={() => fetchBusinessByPIN()}>
                <FormInput name="business-pin" placeholder="رقم الدعم الخاص بالشركة" value={businessPIN} valueChanged={(v) => setBusinessPIN(v.target.value)} />
            </Form>
            <button className="rounded bg-green-700 font-bold text-gray-50 text-lg mt-4" onClick={() => saveSettings()}>
                حفظ
            </button>
            {business && (
                <ColumnFlex>
                    <p className="mt-4 font-bold text-3xl text-gray-700">
                        {business.title}
                        <br />
                        <span className="text-2xl">{business.businessCategory.title}</span>
                    </p>
                    <RowFlex>
                        <input type="file" accept="image/*" ref={uploadLogoRef} className="hidden" onChange={(e) => setNewLogo(e.target.files)} />
                        <input type="file" accept="image/*" ref={uploadBannerRef} className="hidden" onChange={(e) => setNewBanner(e.target.files)} />
                        <img className="cursor-pointer w-48 h-48" src={logoUrl} onClick={() => uploadLogoRef?.current.click()} />
                        <img
                            className="cursor-pointer flex-1 h-48"
                            src={bannerUrl || "https://hubcore.morabaaapps.com/files/e66d5063-c784-4dc9-b4a4-6837510261a2.jpg"}
                            onClick={() => uploadBannerRef?.current.click()}
                        />
                    </RowFlex>
                </ColumnFlex>
            )}
            {lastUpdate && (
                <p className="mt-4 font-bold text-xl text-gray-700">
                    أخر تحديث في: {new Date(lastUpdate.lastUpdateAt).toLocaleDateString()} {new Date(lastUpdate.lastUpdateAt).toLocaleTimeString()}
                </p>
            )}
            <FormInput
                name="setting-search-term"
                placeholder="البحث عن اعداد"
                value={settingSearchTerm}
                valueChanged={(v) => setSettingSearchTerm(v.target.value)}
            />
            {receivedSettings && receivedSettings.length > 0 && (
                <div className="flex flex-col">
                    <div className="mt-4 overflow-x-auto overflow-y-hidden py-2">
                        {receivedSettings.map((x) => (
                            <p
                                key={x.title}
                                className={`h-10 p-2 inline-block cursor-pointer font-bold ${x.title === selectedTab ? "border-b-2 border-gray-800" : ""}`}
                                onClick={() => setSelectedTab(x.title)}>
                                {x.title}
                            </p>
                        ))}
                    </div>
                    {selectedTab && (
                        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-col-5 gap-6 mt-4">
                            {receivedSettings
                                .find((x) => x.title === selectedTab)
                                .settingResponses.map((x) =>
                                    settingSearchTerm.length > 0 ? x.title.indexOf(settingSearchTerm) > -1 ? getSettingsCard(x) : <></> : getSettingsCard(x)
                                )}
                        </div>
                    )}
                </div>
            )}
            <button className="rounded bg-green-700 font-bold text-gray-50 text-lg mt-4" onClick={() => saveSettings()}>
                حفظ
            </button>
        </div>
    );
};

export default BusinessSettingsView;
