import moment from "moment";
import { HubLogsAPI } from "../../api";
import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../Hocks/UseWindowDimensions";

const LevelColors = ["#A35200", "#1890FF", "#FAAD14", "#F5222D", "#FF1010"];
const levelStatus = ["Debug", "Info", "Warn", "Error", "Fatal"];

let canLoadMore = true;

const Levels = [
    { title: "All", id: -1 },
    { title: "Debug", id: 0 },
    { title: "Info", id: 1 },
    { title: "Warn", id: 2 },
    { title: "Error", id: 3 },
    { title: "Fatal", id: 4 },
];

const PagesSize = [
    { title: "25", id: 25 },
    { title: "50", id: 50 },
    { title: "75", id: 75 },
    { title: "100", id: 100 },
];

const DropDown = ({ value, placeholder, valueChanged, opstions }) => (
    <div className="input-conatiner">
        <p className="input-lable">{placeholder} </p>
        <select className="drowp-field" value={value} onChange={valueChanged}>
            {opstions.map((level) => (
                <option value={level.id} key={level.id}>
                    {level.title}
                </option>
            ))}
        </select>
    </div>
);

const LogsLables = ({ item }) => (
    <p className="logs-card-lables">
        <span className="logs-card-title">المعرف</span>
        <span className="logs-card-text">{item.descriminator}</span>
        <br />
        <span className="logs-card-title">المصدر</span>
        <span className="logs-card-text">{item.source}</span>
        <br />
        <span className="logs-card-title">الاصدار</span>
        <span className="logs-card-text">{item.version}</span>
    </p>
);

const InputField = ({ value, placeholder, valueChanged, type = "text" }) => (
    <div className="input-conatiner">
        <p className="input-lable">{placeholder} </p>
        <input type={type} className="input-field" value={value} placeholder={placeholder} onChange={valueChanged} />
    </div>
);

const GeneralLogsView = () => {
    const [logs, setLogs] = useState([]);
    const [pageSize, setPageSize] = useState(parseInt(localStorage.getItem("logsPageSize")) || 25);

    const [descriminator, setDescriminator] = useState("");
    const [source, setSource] = useState("");
    const [level, setLevel] = useState(localStorage.getItem("selectedLevel") || -1);
    const [message, setMessage] = useState("");

    const [dtFrom, setDtFrom] = useState(new Date().toISOString().split("T")[0]);
    const [dtTo, setDtTo] = useState(new Date().toISOString().split("T")[0]);

    const { height } = useWindowDimensions();

    const fetchLogs = (clear = false) => {
        console.log("fetching");
        let url = `/logs/?offset=${clear === true ? 0 : logs.length}&limit=${pageSize}`;
        if (descriminator) url += `&descriminator=${descriminator}`;
        if (+level > -1) url += `&level=${level}`;
        if (dtFrom) url += `&from=${dtFrom}`;
        if (dtTo) url += `&to=${dtTo}`;
        if (message) url += `&message=${message}`;

        HubLogsAPI.get(url).then(({ data }) => {
            canLoadMore = data.data.length >= pageSize;
            data.data.forEach((item) => {
                item.happenedAt = new Date(item.happenedAt).toLocaleString();
                item.descriminator = item.businessTitle || item.descriminator.split("|")[0];
                let arrSource = item.source.split("|");
                item.version = arrSource[1];
                item.source = arrSource[0];
                item.color = LevelColors[item.level];
                item.level = levelStatus[item.level];
            });
            setLogs(clear ? data.data : [...logs, ...data.data]);
        });
    };

    useEffect(() => {
        fetchLogs(true);
    }, [level]);

    const itemsScroll = ({ target }) => {
        if (canLoadMore && target.scrollHeight - target.scrollTop < target.clientHeight + 50) {
            canLoadMore = false;
            fetchLogs(false);
        }
    };

    return (
        <div
            style={{ position: "fixed", inset: 0 }}
            onScroll={itemsScroll}
            style={{ height: height - 150, overflowY: "auto", paddingBottom: 200, paddingTop: 50 }}>
            <div>
                <div>
                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                        <InputField placeholder="من تاريخ" value={dtFrom} valueChanged={({ target }) => setDtFrom(target.value)} type="date" />
                        <InputField placeholder="الى تاريخ" value={dtTo} valueChanged={({ target }) => setDtTo(target.value)} type="date" />
                        <InputField placeholder="المعرف" value={descriminator} valueChanged={({ target }) => setDescriminator(target.value)} />
                        <InputField placeholder="المصدر" value={source} valueChanged={({ target }) => setSource(target.value)} />
                        <InputField placeholder="الرسالة" value={message} valueChanged={({ target }) => setMessage(target.value)} />
                        <DropDown
                            opstions={Levels}
                            placeholder="المستوى"
                            value={level}
                            valueChanged={({ target }) => {
                                setLevel(target.value);
                                localStorage.setItem("selectedLevel", target.value);
                            }}
                        />
                        <DropDown
                            value={pageSize}
                            opstions={PagesSize}
                            placeholder="حجم الصفحة"
                            valueChanged={({ target }) => {
                                localStorage.setItem("logsPageSize", target.value);
                                setPageSize(target.value);
                            }}
                        />
                    </div>
                    <button className="green-btn" onClick={() => fetchLogs(true)}>
                        بحث
                    </button>
                </div>

                <div className="Grid">
                    {logs.map((item) => (
                        <div key={item.id} className="logs-card">
                            <p className="logs-card-header">
                                <span className="logs-level" style={{ backgroundColor: item.color, boxShadow: `0px 0px 7px ${item.color}aa` }}>
                                    {item.level}
                                </span>
                                <span>{item.happenedAt}</span>
                            </p>
                            <LogsLables item={item} title="المعرف " text={item.descriminator} />
                            <p className="logs-card-message">{item.message}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default GeneralLogsView;
