import React from "react";

const NotFound = () => {
    return (
        <div>
            <p dir="ltr" className="font-bold text-center text-3xl text-red-700">
                Not Found 404!
            </p>
        </div>
    );
};

export default NotFound;
