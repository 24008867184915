import React from "react";
import MaterialIcon from "./MaterialIcon";
import NeuCard from "./NeuCard";

const NeuAddCard = (props) => {
    return (
        <NeuCard link={props.link} className="h-full">
            <div className="grid h-full items-center">
                <p className="text-center ">
                    <MaterialIcon icon="add_circle" className="text-6xl text-gray-600 hover:text-green-600" />
                </p>
            </div>
        </NeuCard>
    );
};

export default NeuAddCard;
