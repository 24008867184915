import { HubCore } from "../../api";
import TicketsView from "../Tickets/TicketsView";
import React, { useEffect, useState } from "react";
import AddCredits from "../../Components/AddCredits";
import { useHistory, useParams } from "react-router-dom";
import BusinessesView from "../Businesses/BusinessesView";
import LoaderComponent from "../../Components/LoaderComponent";
import FormTextArea from "../../Components/FormTextArea";
import { ReactComponent as AddCreditsImage } from "../../Assets/Images/addcredits.svg";

const OwnerView = () => {
    const [owner, setOwner] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isAddCreditsShown, setIsAddCreditsShown] = useState(false);
    const isSuperStaff = localStorage.getItem("isSuper") === String(true);
    const [notes, setNotes] = useState("");
    let params = useParams();
    let history = useHistory();

    const fetchOwner = () => {
        HubCore.get(`/owners/${params.id}/`).then((response) => {
            setOwner(response.data);
            setNotes(response.data.notes ?? "");

            setLoading(false);
        });
    };

    const loginAs = () => {
        HubCore.get(`/owners/loginas/${owner.id}`).then((response) => {
            let link = `https://my.morabaa.com/alreadyloggedin/${response.data}`;
            window.open(link, "_blank");
        });
    };

    const refreshOwner = (owner) => setOwner(owner);

    const resetPassword = () => {
        let newPassword = window.prompt("أدخل كلمة السر الجديدة", "1234");
        if (!newPassword) return;
        HubCore.post(`/owners/${params.id}/resetpassword`, {
            password: newPassword,
            passwordConfirmation: newPassword,
        }).then(() => alert("تم"));
    };

    const toggleAddCredits = () => {
        setIsAddCreditsShown(!isAddCreditsShown);
    };

    const deleteOwner = () => {
        if (window.confirm("هل انتَ متأكد") === false) return;
        HubCore.delete(`/owners/${params.id}`).then(() => {
            history.push("/owners");
        });
    };

    const restoreOwner = () => {
        if (loading) return;
        setLoading(true);
        HubCore.post(`/owners/${params.id}/restore`).then(() => {
            fetchOwner();
        });
    };

    const onSubmitNotes = () => {
        HubCore.patch(`/owners/${owner.id}`, { notes });
    };

    useEffect(() => fetchOwner(), []);
    return loading || !owner ? (
        <LoaderComponent />
    ) : (
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-gray-600">
            <p className="font-bold col-span-full">
                <span className="text-3xl">
                    {owner.firstName} {owner.lastName}
                </span>
                <br />
                <span className="text-lg">{owner.phoneNumber}</span>
            </p>
            {owner.isDeleted && (
                <>
                    <span className="col-start-1 text-red-500">محذوف</span>
                    <button className="px-4 py-2 rounded cursor-pointer bg-green-600 text-gray-50 font-bold" onClick={restoreOwner}>
                        إستعادة
                    </button>
                </>
            )}
            <div className="col-span-full"></div>
            <button className="px-4 py-2 rounded cursor-pointer bg-gray-700 text-gray-100 font-bold" onClick={loginAs}>
                Login As
            </button>
            {owner && (
                <button className="px-4 py-2 rounded cursor-pointer bg-blue-700 text-gray-50" onClick={() => resetPassword()}>
                    تغيير كلمة السر
                </button>
            )}
            {owner && isSuperStaff && (
                <button className="px-4 py-2 rounded border-2 cursor-pointer border-red-600 text-red-500 font-bold" onClick={deleteOwner}>
                    حذف
                </button>
            )}
            <div className="flex flex-col gap-4 col-span-full">
                <p className="mt-4 font-bold text-2xl">الشركات</p>
                <hr className="my-2" />
                {owner && owner.id && <BusinessesView ownerId={owner.id} />}
                <hr className="my-2" />
            </div>
            <FormTextArea placeholder="الملاحظة" value={notes} valueChanged={(e) => setNotes(e.target.value)} className="col-span-full" />
            <button className="px-4 py-2 rounded bg-green-600 text-gray-50 font-bold mb-4" onClick={onSubmitNotes}>
                حفظ
            </button>
        </div>
    );
};

export default OwnerView;
