import React, { useState, useEffect } from "react";
import { HubCore } from "../../api";

let queryChangeTimeout = null;

const RepresentativesView = () => {
    const [reps, setReps] = useState([]);
    const [query, setQuery] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [canLoadMore, setCanLoadMore] = useState(false);

    const fetchRepsAsync = async (clear = false) => {
        if (isLoading || (!clear && !canLoadMore)) return;
        try {
            let url = `/representatives/?query=${query}&offset=${clear ? 0 : reps.length}`;
            const { data } = await HubCore.get(url);
            setReps(clear ? data : [...reps, ...data]);
            setCanLoadMore(data.length >= 25);
        } catch {
        } finally {
            setIsLoading(false);
        }
    };

    const resetPassword = async (repId) => {
        if (isLoading) return;
        try {
            if (!window.confirm("هل انت متأكد?")) return;
            const newPassword = window.prompt("كلمة المرور الجديدة");
            if (!newPassword) return;
            setIsLoading(true);
            await HubCore.post(`/representatives/${repId}/resetpassword`, { newPassword: newPassword, newPasswordConfirmation: newPassword });
            alert("تم تغيير كلمة المرور بنجاح");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (queryChangeTimeout !== null) clearTimeout(queryChangeTimeout);
        queryChangeTimeout = setTimeout(() => {
            fetchRepsAsync(true);
        }, 500);
    }, [query]);

    return (
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            <p className="col-span-full font-bold text-xl">المندوبون</p>
            <input
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className="col-span-full border-2 border-gray-600 p-2"
                type="text"
                placeholder="البحث عن مندوب"
            />
            {reps &&
                reps.length > 0 &&
                reps.map((x) => (
                    <div className="flex flex-col gap-4 font-bold shadow-lg rounded p-4 border border-gray-200 hover:shadow-2xl cursor-pointer" key={x.id}>
                        <p>
                            <span className="opacity-70">الإسم الكامل:</span>
                            <br />
                            <span>
                                {x.firstName} {x.lastName}
                            </span>
                            <br />
                            <span className="opacity-70">رقم الهاتف:</span>
                            <br />
                            <span> {x.phoneNumber}</span>
                        </p>
                        <button className="self-start px-4 py-2 rounded bg-blue-700 text-gray-50" onClick={() => resetPassword(x.id)}>
                            تغيير كلمة السر
                        </button>
                    </div>
                ))}
            {canLoadMore && (
                <button type="button" className="rounded px-4 py-2 bg-blue-600 text-white font-bold col-span-full">
                    تحميل المزيد
                </button>
            )}
        </div>
    );
};

export default RepresentativesView;
