import React from "react";

const FormSubmit = ({ value, bgClass = "bg-green-600" }) => {
    return (
        <input
            type="submit"
            className={bgClass + " rounded text-white self-start text-lg font-bold px-4 py-2 mt-4 shadow-lg hover:shadow-md cursor-pointer"}
            value={value || "حفظ"}
        />
    );
};

export default FormSubmit;
