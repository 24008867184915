import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as DragAndDrop } from "../Assets/Images/drag-and-drop.svg";

const DropZone = ({ filesDroped, ...props }) => {
    const [hovered, setHovered] = useState(false);
    const defaultStyle = {
        background: "linear-gradient(45deg, #d9444430, #9c22c447)",
        borderColor: "#d9444430",
    };

    const hoveredStyle = {
        background: "linear-gradient(45deg, #9c22c447, #d9444430)",
        borderColor: "#9c22c447",
    };

    const onDrop = useCallback((acceptedFiles) => {
        filesDroped(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div
            {...getRootProps()}
            className="border-4 border-dotted h-96 cursor-pointer shadow-lg hover:shadow-xl"
            style={hovered ? hoveredStyle : defaultStyle}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}>
            <input {...getInputProps()} />
            {isDragActive ? <p>Drop the files here ...</p> : <DragAndDrop className="h-full mx-auto" />}
        </div>
    );
};

export default DropZone;
