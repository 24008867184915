import { HubCore } from "../../api";
import Form from "../../Components/Form";
import NeuCard from "../../Components/NeuCard";
import React, { useEffect, useState } from "react";
import FormInput from "../../Components/FormInput";
import ColumnFlex from "../../Components/ColumnFlex";
import GridComponent from "../../Components/GridComponent";
import LoaderComponent from "../../Components/LoaderComponent";
import GuestUploadLinkGenerator from "../../Components/GuestUploadLinkGenerator";

const FilesGroupsView = () => {
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newGroupTitle, setNewGroupTitle] = useState("");

    const fetchGroups = () => {
        HubCore.get("/filesgroups")
            .then((response) => {
                setGroups(response.data);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    const submitNewFileGroup = () => {
        if (!newGroupTitle) return false;
        setLoading(true);
        HubCore.post("/filesgroups", {
            title: newGroupTitle,
        }).then((response) => {
            setGroups([...groups, response.data]);
            setLoading(false);
        });
    };

    useEffect(fetchGroups, []);

    return loading ? (
        <LoaderComponent />
    ) : (
        <ColumnFlex>
            <Form onSubmit={submitNewFileGroup} submitText="اضافة" className="mb-4">
                <FormInput
                    name="group-title"
                    placeholder="عنوان المجموعة الجديدة"
                    value={newGroupTitle}
                    valueChanged={(e) => setNewGroupTitle(e.target.value)}
                    required={true}
                />
            </Form>
            <hr className="mb-4" />
            <GuestUploadLinkGenerator />
            <hr className="mb-4" />
            <GridComponent>
                {groups.map((group) => (
                    <NeuCard key={group.id} link={`/filesgroups/${group.id}`} text={group.title} />
                ))}
            </GridComponent>
        </ColumnFlex>
    );
};

export default FilesGroupsView;
