import axios from "axios";
const HubCoreRoot = `https://hubcore.morabaaapps.com/api/v1`;
const HubLogsRoot = "https://log.morabaaapps.com/api/v2";

const getAxiosInstance = (root) => {
    let instance = axios.create({
        baseURL: root,
        headers: {
            "Content-Type": "application/json",
            "App-Package": "com.morabaa.hub",
        },
    });
    instance.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    return instance;
};

export const updateToken = (token) => {
    HubLogsAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    // HubLogsAPI.defaults.headers.common["App-Package"] = "com.morabaa.log";

    HubCore.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    // HubCore.defaults.headers.common["App-Package"] = "com.morabaa.hub";
};

export const HubCore = getAxiosInstance(HubCoreRoot);
export const HubLogsAPI = getAxiosInstance(HubLogsRoot);
export const SeagullAPI = getAxiosInstance("https://seagull.morabaa.com");

// export default HubCore;
