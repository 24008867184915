import { slide as Menu } from 'react-burger-menu'
import React from "react"
import Dashboard from "../../Views/Dashboard";

class BurgerMenu extends React.Component {
  showSettings (event) {
    event.preventDefault();
  
  }

  render () {
    return (
      <Menu right>
        <div className="h-screen" style={{  justifyContent: "center", direction: 'rtl' }}>
          <Dashboard />
         </div>
       </Menu>
    );
  }
}
export default BurgerMenu;
