import React from "react";
import { Link } from "react-router-dom";

const NeuCardLink = (props) => {
    return (
        <Link to={{ pathname: props.link, data: props.data }} onClick={props.onClick} className={`neu-card ${props.className}`} onClick={props.onClick}>
            {props.children}
        </Link>
    );
};

export default NeuCardLink;
