import React from "react";
import Loader from "react-loader-spinner";

const LoaderComponent = () => {
    return (
        <div className="relative">
            <div className="absolute top-1/2 left-1/2" style={{ transform: "translate(-50%, 100%)" }}>
                <Loader type="Circles" color="#c0202f" height={80} width={80} />
            </div>
        </div>
    );
};

export default LoaderComponent;
