import { HubCore } from "../../api";
import LinkCard from "../../Components/LinkCard";
import React, { useEffect, useState } from "react";
import GridComponent from "../../Components/GridComponent";
import LoaderComponent from "../../Components/LoaderComponent";

const BusinesCategoryView = () => {
    const [businesCategary, setBusinesCategary] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const fetchBusinesCategory = () => {
        HubCore.get("businesses/categories")
            .then((result) => {
                setBusinesCategary(result.data);
            })
            .finally(() => setIsLoading(false));
    };
    useEffect(fetchBusinesCategory, []);

    return isLoading ? (
        <LoaderComponent />
    ) : (
        <GridComponent>
            {businesCategary.map((businCate) => (
                <LinkCard to={`/businesses/categories/${businCate.id}/solutions`} data={businCate.title} key={businCate.id}>
                    <p className="px-2 py-4 font-bold rounded bg-gray-100 shadow-lg ">{businCate.title}</p>
                </LinkCard>
            ))}
        </GridComponent>
    );
};

export default BusinesCategoryView;
