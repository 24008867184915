import React, { useEffect, useState } from "react";

import LoaderComponent from "../../Components/LoaderComponent";
import { useParams } from "react-router-dom";
import { HubCore } from "../../api";

const AgentView = () => {
    const [agent, setAgent] = useState(null);
    const [loading, setLoading] = useState(true);

    let params = useParams();

    const fetchAgent = () => {
        HubCore.get(`/agents/${params.id}/`).then((response) => {
            setAgent(response.data);
            setLoading(false);
        });
    };

    useEffect(() => fetchAgent(), []);

    return loading ? (
        <LoaderComponent />
    ) : (
        <div>
            <p className="font-bold text-xl text-gray-600 ">
                <span className="text-2xl">
                    {agent.firstName} {agent.lastName}
                </span>
                <br />
                <span>{agent.phoneNumber}</span>
                <br />
                <span>{agent.province}</span>
            </p>
        </div>
    );
};

export default AgentView;
