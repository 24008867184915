import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { HubCore } from "./api";
import GuardedRoute from "./Components/GuardedRoute";
import PermissionGuardedRoute from "./Components/PermissionGuardedRoute";
import Header from "./Components/Header";
import LoginView from "./Views/Accounts/LoginView";
import AgentsView from "./Views/Agents/AgentsView";
import AgentView from "./Views/Agents/AgentView";
import BusinesCategoryView from "./Views/Businesses/BusinesCategoryView";
import BusinessesSolutionsView from "./Views/Businesses/BusinessesSolutionsView";
import BusinessesView from "./Views/Businesses/BusinessesView";
import BusinessView from "./Views/Businesses/BusinessView";
import SubscriptionDetailsView from "./Views/Businesses/SubscriptionDetailsView";
import CardGenView from "./Views/Cards/CardGenView";
import NewCommandView from "./Views/Commands/NewCommandView";
import Dashboard from "./Views/Dashboard";
import FlaggedDonglesView from "./Views/Dongles/FlaggedDonglesView";
import FilesGroupsView from "./Views/Files/FilesGroupsView";
import FilesView from "./Views/Files/FilesView";
import GuestUploadView from "./Views/Files/GuestUploadView";
import NotFound from "./Views/NotFound";
import Backups from "./Views/OnlineDbBackup/Backups";
import AddAccountBalanceView from "./Views/Owners/AddAccountBalanceView";
import BalancesView from "./Views/Owners/BalancesView";
import OwnersView from "./Views/Owners/OwnersView";
import OwnerView from "./Views/Owners/OwnerView";
import ResponsesView from "./Views/Questionnaire/ResponsesView";
import ResponseView from "./Views/Questionnaire/ResponseView";
import StatisticsView from "./Views/Questionnaire/StatisticsView";
import SolutionsView from "./Views/Solutions/SolutionsView";
import EditStaffView from "./Views/Staff/EditStaffView";
import NewStaffView from "./Views/Staff/NewStaffView";
import PermissionsStaffView from "./Views/Staff/PermissionsStaffView";
import StaffView from "./Views/Staff/StaffView";
import GeneralStatsView from "./Views/Stats/GeneralStatsView";
import SubscriptionsView from "./Views/Subscriptions/SubscriptionsView";
import TicketsView from "./Views/Tickets/TicketsView";
import TicketView from "./Views/Tickets/TicketView";
import BurgerMenu from "./Components/BurgerMenu/BurgerMenu";
import BusinessSettingsView from "./Views/Businesses/BusinessSettingsView";
import BusinessSettingsLogsView from "./Views/Logs/BusinessSettingsLogsView";
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from "./Components/BurgerMenu/DeviceSize";
import GeneralLogsView from "./Views/Logs/GeneralLogsView";
import RepresentativesView from "./Views/Reps/RepresentativesView";
import UploadStatsView from "./Views/Stats/UploadStatsView";
import NewOwnerView from "./Views/Owners/NewOwnerView";

export default function App() {
    const [isSuperStaff, setisSuperStaff] = useState(false);
    const isSuper = () => {
        if (localStorage.getItem("isSuper") === true) setisSuperStaff(true);
        else if (localStorage.getItem("isSuper") === false) setisSuperStaff(false);
        else
            HubCore.get(`/staff/me`).then((response) => {
                setisSuperStaff(response.data.isSuperStaff);
                localStorage.setItem("isSuper", response.data.isSuperStaff);
            });
    };
    const authed = !!localStorage.getItem("token");

    useEffect(() => isSuper(), []);
    const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
    return (
        <Router>
            <div className="w-full">
                <Header />
                <div className="flex flex-row">
                    {authed && (
                        <div className="w-full bg-white" style={{ width: isMobile ? "0%" : "18%", justifyContent: "center" }}>
                            {isMobile ? <BurgerMenu /> : <Dashboard className="flex flex-col"></Dashboard>}
                        </div>
                    )}
                    <div
                        style={{ width: isMobile ? "100%" : "82%", overflow: "auto", direction: "rtl", marginBottom: "35px" }}
                        className="container  p-5 h-full w-full">
                        <div style={{ direction: "rtl", marginBottom: "35px" }}>
                            <Switch>
                                <Route path="/login" exact component={LoginView} />
                                <Route path="/upload/:token" exact component={GuestUploadView} />
                                <GuardedRoute path="/" exact component={OwnersView} />
                                <GuardedRoute path="/agents/" exact component={AgentsView} />
                                <GuardedRoute path="/owners/" exact component={OwnersView} />
                                <GuardedRoute path="/owners/new" exact component={NewOwnerView} />
                                <GuardedRoute path="/owners/:id/tickets" exact component={TicketsView} />
                                <GuardedRoute path="/owners/:id/balance" exact component={BalancesView} />
                                <GuardedRoute path="/owners/:id/" exact component={OwnerView} />
                                <GuardedRoute path="/tickets/" exact component={TicketsView} />
                                <GuardedRoute path="/tickets/:id" exact component={TicketView} />
                                <GuardedRoute path="/agents/:id/" exact component={AgentView} />
                                <GuardedRoute path="/cards/gen/" exact component={CardGenView} />
                                <PermissionGuardedRoute permissions={["CREATE_STAFF", "ASSIGN_STAFF_TO_BUSINESS"]} path="/staff/" exact component={StaffView} />
                                <PermissionGuardedRoute
                                    permissions={["CREATE_STAFF", "ASSIGN_STAFF_TO_BUSINESS"]}
                                    path="/staff/new/"
                                    exact
                                    component={NewStaffView}
                                />
                                <PermissionGuardedRoute
                                    permissions={["CREATE_STAFF", "ASSIGN_STAFF_TO_BUSINESS"]}
                                    path="/staff/:id/edit/"
                                    exact
                                    component={EditStaffView}
                                />
                                <PermissionGuardedRoute
                                    permissions={["CREATE_STAFF", "ASSIGN_STAFF_TO_BUSINESS"]}
                                    path="/staff/:id/"
                                    exact
                                    component={PermissionsStaffView}
                                />
                                <GuardedRoute path="/filesgroups/:id/" exact component={FilesView} />
                                <GuardedRoute path="/businesses/" exact component={BusinessesView} />
                                <GuardedRoute path="/businesses/categories/" exact component={BusinesCategoryView} />
                                <GuardedRoute path="/businesses/categories/:id/solutions" exact component={BusinessesSolutionsView} />
                                <GuardedRoute path="/businesses/support/" exact component={BusinessSettingsView} />
                                <GuardedRoute path="/businesses/:id/" exact component={BusinessView} />
                                <GuardedRoute path="/businesses/:id/newcommand" exact component={NewCommandView} />
                                <GuardedRoute path="/filesgroups/" exact component={FilesGroupsView} />
                                <GuardedRoute path="/addaccountbalanceview/:id/" exact component={AddAccountBalanceView} />
                                <GuardedRoute path="/subs/:solutionId/:ownerId/" exact component={SubscriptionDetailsView} />
                                {/* <GuardedRoute path="/actions/" exact component={OwnerTrackerView} /> */}
                                <GuardedRoute path="/reps/" exact component={RepresentativesView} />
                                <GuardedRoute path="/stats/" exact component={GeneralStatsView} />
                                {isSuperStaff && <GuardedRoute path="/questionnaire/" exact component={ResponsesView} />}
                                {isSuperStaff && <GuardedRoute path="/questionnaire/statistics/" exact component={StatisticsView} />}
                                {isSuperStaff && <GuardedRoute path="/questionnaire/:id" exact component={ResponseView} />}
                                {isSuperStaff && <GuardedRoute path="/dongles/flagged/" exact component={FlaggedDonglesView} />}
                                {isSuperStaff && <GuardedRoute path="/solutions/" exact component={SolutionsView} />}
                                {isSuperStaff && <GuardedRoute path="/logs/businesssettings" exact component={BusinessSettingsLogsView} />}
                                <GuardedRoute path="/subscriptions/" exact component={SubscriptionsView} />
                                <GuardedRoute path="/backups/" exact component={Backups} />
                                <GuardedRoute path="/generalogs/" exact component={GeneralLogsView} />
                                <GuardedRoute path="/stats/upload" exact component={UploadStatsView} />
                                <Route component={NotFound} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </Router>
    );
}
