import React from "react";
import FormLabel from "./FormLabel";
import FormTextInput from "./FormTextInput";

const FormInput = ({ name, placeholder, value, valueChanged, required, type, label }) => {
    return (
        <>
            <FormLabel htmlFor={name} text={label || placeholder} className="mr-2" />
            <FormTextInput name={name} placeholder={placeholder} value={value} valueChanged={valueChanged} required={required} type={type} />
        </>
    );
};

export default FormInput;
