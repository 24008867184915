import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { HubCore } from "../api";
import SideNeuCard from "../Components/SideMenu/SideNeuCard";

const Dashboard = () => {
    const [isSuperStaff, setisSuperStaff] = useState(false);

    let history = useHistory();

    const logOut = (e) => {
        e.preventDefault();
        localStorage.clear();
        history.push("/");
        return false;
    };

    const isSuper = () => {
        if (localStorage.getItem("isSuper") === true) setisSuperStaff(true);
        else if (localStorage.getItem("isSuper") === false) setisSuperStaff(false);
        else
            HubCore.get(`/staff/me`).then((response) => {
                setisSuperStaff(response.data.isSuperStaff);
                localStorage.setItem("isSuper", response.data.isSuperStaff);
            });
    };

    useEffect(() => isSuper(), []);

    return (
        <div style={{ direction: "ltr" }} className="w-full lg:h-screen overflow-auto">
            <div className="flex flex-col">
                <SideNeuCard link="/owners/" text="الزبائن" />
                <SideNeuCard link="/businesses/" text="الشركات" />
                <SideNeuCard link="/reps/" text="المندوبون" />
                <SideNeuCard link="/stats/upload" text="Upload Stats" />
                <SideNeuCard link="/filesgroups/" text="الملفات" />
                <SideNeuCard link="/businesses/categories/" text="تصنيف الشركات" />
                <SideNeuCard link="/tickets/" text="التذاكر" />
                <SideNeuCard link="/dongles/flagged/" text="Flagged Dongles" />
                <SideNeuCard link="/subscriptions/" text="الإشتراكات" />
                <SideNeuCard link="/backups/" text="Backups" />
                <SideNeuCard link="/staff/" text="المستخدمون" />
                <SideNeuCard link="/generalogs/" text="General Logs" />
                {isSuperStaff && <SideNeuCard link="/questionnaire/" text="تقييمات الوكلاء" />}
                {isSuperStaff && <SideNeuCard link="/actions/" text="Owners Actions" />}
                {isSuperStaff && <SideNeuCard link="/solutions/" text="الخدمات" />}
                {isSuperStaff && <SideNeuCard link="/logs/businesssettings/" text="Settings' Logs" />}
                {/* {isSuperStaff && <NeuCard link="/stats/" text="الخُلاصات" />} */}
                <SideNeuCard link="/" text="تسجيل الخروج" onClick={logOut} />
            </div>
        </div>
    );
};

export default Dashboard;
