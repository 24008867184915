import { HubCore } from "../../api";
import NewStaff from "../../Components/NewStaff";
import React, { useEffect, useState } from "react";
import LoaderComponent from "../../Components/LoaderComponent";

const StaffEdit = (props) => {
    const [staff, setStaff] = useState([]);
    const [loading, setLoading] = useState(true);
    const { data } = props.location;

    const fetchStaff = () => {
        if (data) {
            setStaff(data);
            setLoading(false);
        } else
            HubCore.get("/staff/").then((response) => {
                setStaff(response.data);
                setLoading(false);
            });
    };
    useEffect(fetchStaff, []);

    return loading ? <LoaderComponent /> : <NewStaff staff={staff} />;
};

export default StaffEdit;
