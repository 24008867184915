import React from "react";
import ColumnFlex from "./ColumnFlex";
import InstallPWA from "./InstallPWA";
import { Link } from "react-router-dom";
import { useLocalStorage } from "@rehooks/local-storage";
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from "../Components/BurgerMenu/DeviceSize";

const Header = () => {
    const [user] = useLocalStorage("user");
    const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

    return (
        <ColumnFlex>
            <Link to="/" className="shadow-lg">
                <div className="MainHeader">
                    {isMobile ? (
                        <></>
                    ) : (
                        <div className="logoHeader w-full h-full" style={{ width: isMobile ? "260px" : "18%" }}>
                            <p className="morabaa md:text-4xl lg:text-5xl xl:text-6xl content-center  ">المربع</p>
                        </div>
                    )}
                    <div className=" justify-center items-center">
                        <h2
                            className=" p-7 font-bold md:text-lg lg:text-lg xl:text-xl 2xl:text-2xl text-gray-50 items-center content-center justify-center font-cairo  "
                            style={{ marginRight: isMobile ? "40px" : "1px" }}>
                            مرحبًا {user?.fullName || ""}
                        </h2>
                    </div>
                </div>
            </Link>
            <InstallPWA />
        </ColumnFlex>
    );
};

export default Header;
