import { HubCore } from "../../api";
import Form from "../../Components/Form";
import React, { useEffect, useState } from "react";
import OwnerCard from "../../Components/OwnerCard";
import FormSearch from "../../Components/FormSearch";
import GridComponent from "../../Components/GridComponent";
import LoaderComponent from "../../Components/LoaderComponent";
import FormTextInput from "../../Components/FormTextInput";

const OwnersView = () => {
    const [loading, setLoading] = useState(true);
    const [ownersDto, setOwnersDto] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [newOwnerAddress, setNewOwnerAddress] = useState("");
    const [showDialog, setShowDialog] = useState(false);
    const [selectedOwner, setSelectedOwner] = useState("");
    const [totals, setTotals] = useState({ owners: 0, businesses: 0 });
    const fetchOwners = () => {
        HubCore.get(`/owners`).then((response) => {
            setOwnersDto(response.data);
            setLoading(false);
        });
    };

    const fetchTotals = () => HubCore.get("/totals").then((response) => setTotals(response.data));

    const fetchOwner = () => {
        HubCore.get(`/owners/?Q=${searchTerm}`)
            .then((response) => setOwnersDto(response.data))
            .finally(() => setLoading(false));
    };

    const ChangeOwnerAddress = (_selectedOwner) => {
        setSelectedOwner(_selectedOwner);
        setShowDialog(true);
    };

    const onSubmitAddresChange = () => {
        setShowDialog(false);
        HubCore.patch(`/owners/${selectedOwner.id}`, { address: newOwnerAddress }).then((result) => {
            let newOwners = [];
            for (let i = 0; i < ownersDto.length; i++) {
                let ownerToAdd = ownersDto[i];
                if (ownerToAdd.owner.id === selectedOwner.id) ownerToAdd.owner = result.data;
                newOwners.push(ownerToAdd);
            }
            setOwnersDto(newOwners);
        });
    };

    const init = () => {
        fetchOwners();
        fetchTotals();
    };

    useEffect(init, []);

    const cancelButton = (
        <button className="px-4 py-2 mt-4 mr-2 rounded bg-red-700 text-gray-100 font-bold" onClick={() => setShowDialog(false)}>
            إلغاء
        </button>
    );

    return loading ? (
        <LoaderComponent />
    ) : (
        <>
            <FormSearch
                name="owner-name"
                className="mb-4"
                value={searchTerm}
                onSubmit={fetchOwner}
                placeholder="إسم أو رقم هاتف الزبون"
                valueChanged={(e) => setSearchTerm(e.target.value)}
            />
            <p className="mb-4">
                <span>عدد الزبائن الكلي: </span>
                <span className="font-bold">{totals.owners}</span>
                <br />
                <span>عدد الشركات الكلي: </span>
                <span className="font-bold">{totals.businesses}</span>
            </p>

            <GridComponent lgCols="3" mdCols="2" gap="4">
                <a href="/owners/new" className="col-span-full text-green-700 font-bold">
                    إضافة زبون جديد
                </a>
                {ownersDto.length > 0 &&
                    ownersDto.map((ownerDto) => <OwnerCard ownerDto={ownerDto} ChangeOwnerAddress={ChangeOwnerAddress} key={ownerDto.owner.id} />)}
            </GridComponent>
            {showDialog && (
                <div
                    className="fixed inset-0"
                    onClick={(e) => {
                        if (String(e.target.tagName) === "DIV") setShowDialog(false);
                    }}
                    style={{ backgroundColor: "rgba(12,12,12,0.5)" }}>
                    <Form
                        onSubmit={onSubmitAddresChange}
                        footer={cancelButton}
                        submitText="تغيير"
                        className="relative rounded-md bg-gray-700 p-3 mx-4 top-1/2 max-w-md right-1/4">
                        <FormTextInput
                            name={"owner-addres"}
                            type="text"
                            value={newOwnerAddress}
                            placeholder={`العنوان الجديد لـ ${selectedOwner.firstName} ${selectedOwner.lastName}`}
                            valueChanged={(e) => setNewOwnerAddress(e.target.value)}
                        />
                    </Form>
                </div>
            )}
        </>
    );
};

export default OwnersView;
