import React from "react";
import FormLabel from "./FormLabel";

const FormTextArea = (props) => {
    return (
        <div className={"flex flex-col" + (props.className ? ` ${props.className}` : "")}>
            <FormLabel htmlFor={props.name} text={props.label || props.placeholder} />
            <textarea
                className="px-4 py-2 bg-gray-100"
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.valueChanged}
                required={props.required}
                rows={props.rows || 10}
            />
        </div>
    );
};

export default FormTextArea;
