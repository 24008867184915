import React from "react";

const FormTextInput = ({ name, placeholder, value, valueChanged, required, type, step = null, min = 0 }) => {
    return (
        <input
            type={type ? type : "text"}
            id={name}
            name={name}
            value={value}
            onChange={valueChanged}
            placeholder={placeholder}
            required={required}
            step={step || "any"}
            min={min || 0}
            className="px-4 py-2 text-gray-800 rounded border"></input>
    );
};

export default FormTextInput;
