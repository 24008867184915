import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { HubCore } from "../../api";
import ColumnFlex from "../../Components/ColumnFlex";
import Form from "../../Components/Form";
import FormInput from "../../Components/FormInput";

const commands = [
    { key: "reuploaditems", title: "رفع المواد" },
    { key: "reuploadaccounts", title: "رفع الحسابات" },
    { key: "resetmovmentid", title: "رفع الملخص" },
];

const NewCommandView = (props) => {
    const [key, setKey] = useState("");
    const [title, setTitle] = useState("");
    const [business, setBusiness] = useState(null);
    let params = useParams();

    const fetchBusiness = () => {
        let url = `/businesses/${params.id}`;

        HubCore.get(url).then((response) => {
            setBusiness(response.data);
        });
    };

    const saveCommand = () => {
        HubCore.post("https://commands.morabaaapps.com/api/v1/commands/", {
            commandKey: key,
            title,
            businessId: business.id,
        }).then(() => {
            setKey("");
            setTitle("");
        });
    };

    useEffect(() => {
        if (props.location.data) setBusiness(props.location.data);
        else fetchBusiness();
    }, []);

    return business ? (
        <ColumnFlex>
            <p className="mb-2 font-bold text-gray-700 text-2xl">امر جديد لـ {business.title}</p>
            <Form onSubmit={saveCommand}>
                <FormInput name="key" placeholder="Command Key" value={key} valueChanged={(e) => setKey(e.target.value)} />
                <FormInput name="title" placeholder="Command Title" value={title} valueChanged={(e) => setTitle(e.target.value)} required={true} />
            </Form>
            <div className="grid gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-5 mt-4">
                {commands.map((x) => (
                    <div
                        key={x.key}
                        className="p-4 rounded shadow-xl cursor-pointer"
                        onClick={() => {
                            setKey(x.key);
                            setTitle(x.title);
                        }}>
                        {x.title}
                    </div>
                ))}
            </div>
        </ColumnFlex>
    ) : (
        <></>
    );
};

export default NewCommandView;
