import { HubCore } from "../../api";
import NeuCard from "../../Components/NeuCard";
import React, { useState, useEffect } from "react";
import GridComponent from "../../Components/GridComponent";
import LoaderComponent from "../../Components/LoaderComponent";

const FlaggedDonglesView = () => {
    const [dongles, setDongles] = useState([]);

    const fetchDongles = () => HubCore.get("/dongles/flagged").then((response) => setDongles(response.data));

    useEffect(() => {
        fetchDongles();
    }, []);

    return dongles ? (
        <GridComponent>
            {dongles.map((dongle) => (
                <NeuCard key={dongle.id} text={dongle.title}></NeuCard>
            ))}
        </GridComponent>
    ) : (
        <LoaderComponent />
    );
};

export default FlaggedDonglesView;
