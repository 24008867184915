import React, { useEffect, useState } from "react";
import { HubCore } from "../api";
import GridComponent from "./GridComponent";
import LoaderComponent from "./LoaderComponent";
import MaterialIcon from "./MaterialIcon";
import RowFlex from "./RowFlex";

const AddPermissions = (props) => {
    const [staffPermission, setStaffPermission] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchPermission = () => {
        HubCore.get("/permissions/").then((response) => {
            setPermissions(response.data);
        });
    };
    useEffect(() => fetchPermission(), []);

    const fetchStaff = () => {
        setLoading(true);
        let str = [];
        HubCore.get(`/staff/${props.id}`).then((response) => {
            response.data.permissions?.forEach((element) => {
                str.push(element.key);
            });
            setStaffPermission(str);
            setLoading(false);
        });
    };
    useEffect(() => fetchStaff(), []);

    useEffect(() => {
        props.onSuccess();
    }, [staffPermission]);

    const addPermission = (permission) => {
        const data = { key: permission };
        HubCore.post(`/staff/${props.id}/permissions`, data).then(() => {
            setStaffPermission([...staffPermission, permission]);
        });
    };

    const removePermission = (permission) => {
        HubCore.delete(`/staff/${props.id}/permissions/${permission}`).then(() => {
            setStaffPermission(staffPermission.filter((perm) => perm != permission));
        });
    };

    return loading ? (
        <LoaderComponent />
    ) : (
        <GridComponent>
            {permissions ? (
                permissions.map((permission) => (
                    <RowFlex className=" justify-between py-2 bg-white px-2  mt-4 rounded-md  shadow-lg" key={permission.key}>
                        {staffPermission.includes(permission.key) ? (
                            <div onClick={() => removePermission(permission.key)} className="cursor-pointer mt-1">
                                <MaterialIcon icon="remove_circle" className="text-red-500 text-2xl  self-center" />
                            </div>
                        ) : (
                            <div onClick={() => addPermission(permission.key)} className="cursor-pointer mt-1">
                                <MaterialIcon icon="add_circle" className="text-green-500 text-2xl self-center" />
                            </div>
                        )}
                        <p className="self-center">{permission.title}</p>
                    </RowFlex>
                ))
            ) : (
                <></>
            )}
        </GridComponent>
    );
};

export default AddPermissions;
