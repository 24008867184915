import React from "react";

const FormLabel = ({ htmlFor, text, ...props }) => {
    return (
        <label htmlFor={htmlFor} className={`mb-1 mt-2 font-bold text-gray-700 text-lg ${props.className || ""}`}>
            {text}
        </label>
    );
};

export default FormLabel;
