import { HubCore } from "../../api";
import StaffCard from "../../Components/StaffCard";
import React, { useEffect, useState } from "react";
import ColumnFlex from "../../Components/ColumnFlex";
import NeuAddCard from "../../Components/NeuAddCard";
import GridComponent from "../../Components/GridComponent";
import LoaderComponent from "../../Components/LoaderComponent";

const StaffsView = () => {
    const [staff, setStaff] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchStaff = () => {
        HubCore.get("/staff/").then((response) => {
            setStaff(response.data);
            setLoading(false);
        });
    };
    useEffect(fetchStaff, []);

    const deleteStaff = (_staff) => HubCore.delete(`/staff/${_staff.id}`).then(() => setStaff(staff.filter((stf) => stf.id !== _staff.id)));

    return loading ? (
        <LoaderComponent />
    ) : (
        <ColumnFlex>
            <GridComponent lgCols="4">
                <NeuAddCard link="/staff/new" />
                {staff.map((staff) => (
                    <StaffCard staff={staff} deleteStaff={deleteStaff} key={staff.id} />
                ))}
            </GridComponent>
        </ColumnFlex>
    );
};

export default StaffsView;
