import React from "react";

const ProgressRing = ({ radius, progress, strokColor = "#25a394", strokeWidth = 5, additionalText = "", ...props }) => {
    const normalizedRadius = radius - strokeWidth * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (progress / 100) * circumference;

    return (
        <svg height={radius * 2} width={radius * 2} className={props.className || ""}>
            <g>
                <circle
                    stroke={strokColor}
                    fill="transparent"
                    strokeDasharray={circumference + " " + circumference}
                    style={{ strokeDashoffset }}
                    strokeWidth={strokeWidth}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
                {additionalText ? (
                    <>
                        <text fill={strokColor} x="50%" y="50%" fontSize="44" textAnchor="middle">
                            {progress}%
                        </text>
                        <text fill="#242426" x="50%" y="70%" fontSize="24" textAnchor="middle">
                            {additionalText}
                        </text>
                    </>
                ) : (
                    <text fill={strokColor} x="50%" y="50%" fontSize="44" textAnchor="middle">
                        {progress}%
                    </text>
                )}
            </g>
        </svg>
    );
};

export default ProgressRing;
