import moment from "moment";
import React, { useEffect, useState } from "react";
import { HubCore } from "../../api";
import GridComponent from "../../Components/GridComponent";
import LoaderComponent from "../../Components/LoaderComponent";

const Backups = ({ businessId = null, ...props }) => {
    const [backups, setBackups] = useState([]);
    const [view, setView] = useState([]);

    const fetchBackups = () =>
        HubCore.get(`https://onlinedbbackup.morabaaapps.com/api/v1/backups?businessId=${businessId || ""}`).then((response) => setBackups(response.data));
    const fetchBusinesses = () =>
        HubCore.post(
            "/businesses/getbusinesses",
            backups.map((x) => x.businessId)
        ).then((response) => {
            let viewsToSet = [];
            for (let i = 0; i < backups.length; i++) {
                let b = response.data.find((y) => y.id === backups[i].businessId);
                if (!b) continue;
                viewsToSet.push({ ...backups[i], businessTitle: b?.title });
            }
            setView(viewsToSet);
        });

    useEffect(() => fetchBackups(), []);
    useEffect(() => fetchBusinesses(), [backups]);

    return view ? (
        <GridComponent>
            {view.map((x) => (
                <a key={x.id} href={`https://onlinedbbackup.morabaaapps.com/backups/${x.fileName}`} target="_blank">
                    <div className="p-4 border border-gray-200 rounded shadow-lg hover:shadow-xl">
                        <p className="p-4">
                            {x.businessTitle}
                            <br />
                            <span className="font-bold text-green-900">{moment(x.createdAt).fromNow()}</span>
                        </p>
                    </div>
                </a>
            ))}
        </GridComponent>
    ) : (
        <LoaderComponent />
    );
};

export default Backups;
