import { HubCore } from "../../api";
import { Root } from "../../consts";
import Form from "../../Components/Form";
import { useParams } from "react-router-dom";
import NeuCard from "../../Components/NeuCard";
import React, { useEffect, useState } from "react";
import ColumnFlex from "../../Components/ColumnFlex";
import FormSearch from "../../Components/FormSearch";
import GridComponent from "../../Components/GridComponent";
import LoaderComponent from "../../Components/LoaderComponent";
const FilesView = () => {
    const [files, setFiles] = useState({});
    const [newFiles, setNewFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadSpeed, setUploadSpeed] = useState(0);
    let params = useParams();

    const fetchFiles = () => {
        HubCore.get(`/files/?groupId=${params.id}&filename=${searchTerm}`)
            .then((response) => {
                let newFiles = {};
                response.data.forEach((file) => {
                    let dateKey = file.uploadedAt.split("T")[0];
                    if (newFiles.hasOwnProperty(dateKey)) newFiles[dateKey].push(file);
                    else newFiles[dateKey] = [file];
                });
                setFiles(newFiles);
            })
            .finally(() => setLoading(false));
    };

    const filesChanged = (e) => setNewFiles(e.target.files);

    const uploadFiles = () => {
        if (!newFiles) return;

        let started = new Date();

        const config = {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                if (percentCompleted !== uploadProgress) setUploadProgress(percentCompleted);
                let elapsed = new Date() - started;
                setUploadSpeed(Math.round(progressEvent.loaded / (elapsed / 1000) / 1024));
            },
        };

        setLoading(true);
        let formData = new FormData();
        for (let i = 0; i < newFiles.length; i++) {
            formData.append("files", newFiles[i], newFiles[i].name);
        }
        formData.append("groupId", params.id);
        HubCore.post("/files", formData, config).then(() => fetchFiles());
    };

    const getFileCard = (file) => (
        <NeuCard key={file.id} link={`${Root}${file.filePath}`} className="px-4 py-2">
            <p className="overflow-ellipsis overflow-hidden">
                <span className="font-bold">{file.fileName}</span>
                <br />
                <span>{new Date(file.uploadedAt).toLocaleString()}</span>
            </p>
        </NeuCard>
    );

    const getFilesCards = (files) => files.map((file) => getFileCard(file));

    const getFilesView = () => {
        return Object.keys(files).map((dateKey) => {
            return (
                <ColumnFlex key={dateKey}>
                    <p className="text-xl font-bold text-gray-800 mb-2 mx-2">{dateKey}</p>
                    <GridComponent>{getFilesCards(files[dateKey])}</GridComponent>
                </ColumnFlex>
            );
        });
    };

    useEffect(fetchFiles, []);

    return loading ? (
        <ColumnFlex>
            <p className="text-bold text-4xl text-center my-2">
                {uploadProgress}%
                <br />
                {uploadSpeed} KB/s
            </p>
            <LoaderComponent />
        </ColumnFlex>
    ) : (
        <ColumnFlex>
            <Form onSubmit={uploadFiles} className="mb-4">
                <input type="file" onChange={filesChanged} multiple={true} />
            </Form>
            <hr className="mb-4" />
            <FormSearch
                onSubmit={fetchFiles}
                submitText="بحث"
                className="mb-4"
                name="filename"
                placeholder="إسم الملف"
                value={searchTerm}
                valueChanged={(e) => setSearchTerm(e.target.value)}
                required={true}></FormSearch>
            <hr className="mb-4" />
            {getFilesView()}
        </ColumnFlex>
    );
};

export default FilesView;
