import React from "react";

const GridComponent = (props) => {
    const mdCols = props.mdCols ? `md:grid-cols-${props.mdCols}` : "md:grid-cols-3";
    const lgCols = props.lgCols ? `lg:grid-cols-${props.lgCols}` : "lg:grid-cols-5";
    const gridGap = `gap-${props.gap || "2"}`;

    return <div className={`mb-16 grid auto-rows-auto ${gridGap} md:gap-3 lg:gap-4 grid-cols-1 ${mdCols} ${lgCols}`}>{props.children}</div>;
};

export default GridComponent;
