import React, { useState } from "react";
import { HubCore } from "../../api";
import FormTextInput from "../../Components/FormTextInput";

const NewOwnerView = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [newOwner, setNewOwner] = useState({
        firstName: "",
        lastName: "",
        password: "",
        phoneNumber: "",
    });

    const submit = async (e) => {
        e.preventDefault();
        if (isLoading) return false;
        try {
            setIsLoading(true);
            const response = await HubCore.post("/owners", {
                first_name: newOwner.firstName,
                last_name: newOwner.lastName,
                password: newOwner.password,
                passwordConfirm: newOwner.password,
                phone_number: newOwner.phoneNumber,
            });
            if (response.status >= 200 && response.status < 300) {
                setNewOwner({
                    firstName: "",
                    lastName: "",
                    password: "",
                    phoneNumber: "",
                });
            }
        } catch (e) {
            console.error(e);
            alert("حدث خطأ أثناء إضافة المالك");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={submit} className="flex flex-col gap-4">
            <FormTextInput
                required={true}
                placeholder="الاسم"
                value={newOwner.firstName}
                valueChanged={(e) => setNewOwner({ ...newOwner, firstName: e.target.value })}
            />
            <FormTextInput
                required={true}
                placeholder="اللقب"
                value={newOwner.lastName}
                valueChanged={(e) => setNewOwner({ ...newOwner, lastName: e.target.value })}
            />
            <FormTextInput
                type="password"
                required={true}
                placeholder="كلمة المرور"
                value={newOwner.password}
                valueChanged={(e) => setNewOwner({ ...newOwner, password: e.target.value })}
            />
            <FormTextInput
                required={true}
                placeholder="رقم الهاتف"
                value={newOwner.phoneNumber}
                valueChanged={(e) => setNewOwner({ ...newOwner, phoneNumber: e.target.value })}
            />
            <button type="submit" className="px-4 py-2 mt-4 mr-2 rounded bg-green-700 text-gray-100 font-bold">
                إضافة
            </button>
        </form>
    );
};

export default NewOwnerView;
