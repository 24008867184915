import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HubCore } from "../../api";
import AddPermission from "../../Components/AddPermissions";
import LoaderComponent from "../../Components/LoaderComponent";
const StaffView = () => {
    const [staff, setStaff] = useState(null);
    const [loading, setLoading] = useState(true);

    let params = useParams();
    const fetchStaff = () => {
        HubCore.get(`/staff/${params.id}`).then((response) => {
            setStaff(response.data);
            setLoading(false);
        });
    };
    useEffect(() => fetchStaff(), []);

    return loading ? (
        <LoaderComponent />
    ) : staff ? (
        <>
            <div className="mt-5">
                <p className="font-bold text-xl text-gray-600 ">
                    <span className="text-2xl">
                        {staff.firstName} {staff.lastName}
                    </span>
                    <br />
                    <span>{staff.phoneNumber}</span>
                    <br />
                    <br />
                </p>
                <ul className="list-disc">
                    {staff.permissions?.map((permissions) => (
                        <li key={permissions.key} className="font-semibold ">
                            {permissions.title}
                        </li>
                    ))}
                </ul>
            </div>
            <AddPermission onSuccess={fetchStaff} id={params.id} />
        </>
    ) : (
        <></>
    );
};

export default StaffView;
