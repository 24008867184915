import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { HubCore } from "../../api";
import Form from "../../Components/Form";
import FormInput from "../../Components/FormInput";
import GridComponent from "../../Components/GridComponent";

const AddAccountBalance = () => {
    const [credits, setGift] = useState("");
    const [cardCash, setCardCash] = useState("");
    const [cash, setCash] = useState("");
    const params = useParams();
    const onSubmit = () => {
        HubCore.post(`/owners/${params.id}/gift`, { credits }).then(() => alert("ok"));
    };
    const onCashSubmit = () => {
        HubCore.post(`/owners/${params.id}/gift`, { credits: cash }).then(() => alert("ok"));
    };
    return (
        <GridComponent mdCols="2" lgCols="3" gap="4">
            <Form onSubmit={onSubmit} submitText="ارسال" className="p-4 shadow-md rounded">
                <FormInput
                    name="card-cash"
                    placeholder="رقم البطاقة"
                    label="بطاقة"
                    value={cardCash}
                    valueChanged={(e) => setCardCash(e.target.value)}></FormInput>
            </Form>

            <Form onSubmit={onCashSubmit} submitText="ارسال" className="p-4 shadow-md rounded">
                <FormInput name="card-cash" label="إضافة رصيد نقدي" placeholder="المبلغ" value={cash} valueChanged={(e) => setCash(e.target.value)}></FormInput>
            </Form>

            <Form onSubmit={onSubmit} submitText="ارسال" className="p-4 shadow-md rounded">
                <FormInput name="card-cash" placeholder="المبلغ" label="هدية" value={credits} valueChanged={(e) => setGift(e.target.value)}></FormInput>
            </Form>
        </GridComponent>
    );
};

export default AddAccountBalance;
