import React from "react";
import NeuAnchor from "./NeuAnchor";
import ColumnFlex from "./ColumnFlex";
import NeuCardLink from "./NeuCardLink";

const NeuCard = (props) => {
    const getLinkChild = () => (
        <ColumnFlex>
            {props.text ? <p
                // style={{ fontSize: 18, }}
                className="p-4 text-center flex-1 flex w-full items-center justify-center h-full md:text-base lg:text-xl 2xl:text-2xl text-gray-700">{props.text}</p> : <></>}
            {props.children}
        </ColumnFlex>
    );

    return props.link?.indexOf("http") > -1 ? (
        <NeuAnchor link={props.link} className={props.className}>
            {getLinkChild()}
        </NeuAnchor>
    ) : (
        <NeuCardLink link={props.link} className={props.className} onClick={props.onClick}>
            {getLinkChild()}
        </NeuCardLink>
    );
};

export default NeuCard;
