import React, { useEffect, useState } from "react";
import { HubCore } from "../../api";
import ColumnFlex from "../../Components/ColumnFlex";

const GeneralStatsView = () => {
    const [totals, setTotals] = useState({ owners: 0, businesses: 0 });
    const fetchTotals = () => HubCore.get("/totals").then((response) => setTotals(response.data));

    return (
        <ColumnFlex>
            <p className="font-bold text-gray-700 text-2xl">الخلاصات</p>
        </ColumnFlex>
    );
};

export default GeneralStatsView;
