import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link, useParams } from "react-router-dom";
import { HubCore } from "../../api";
import ColumnFlex from "../../Components/ColumnFlex";
import LoaderComponent from "../../Components/LoaderComponent";
import MaterialIcon from "../../Components/MaterialIcon";
import NeuCard from "../../Components/NeuCard";

const TicketView = () => {
    const [ticket, setTicket] = useState([]);
    const [loading, setLoading] = useState(true);
    const [ticketStates, setTicketStates] = useState(null);
    const [attachments, setAttachments] = useState([]);
    const [rating, setRating] = useState(null);
    const [business, setBusiness] = useState(null);
    const [owner, setOwner] = useState(null);
    const [ticketState, setTicketState] = useState(null);
    const [replies, setReplies] = useState([]);
    const [newReply, setNewReply] = useState("");
    const [userNameById, setUserNameById] = useState({});

    let routeParams = useParams();
    let history = useHistory();
    var ticketsRoot = "https://tickets.morabaaapps.com/api/v1";
    let ticketUrl = `${ticketsRoot}/tickets/${routeParams.id}`;

    const getLocalDt = (dateTime) => {
        return new Date(dateTime).toLocaleString();
    };

    const fetchTicket = () => {
        HubCore.get(ticketUrl).then((resp) => {
            setLoading(false);
            setTicket(resp.data.ticket);
            setAttachments(resp.data.attachments);
            setTicketStates(resp.data.states);
            setRating(resp.data.rating);
        });
    };

    const fetchReplies = () => HubCore.get(`${ticketsRoot}/replies/ofticket/${ticket.id}`).then((response) => setReplies(response.data));

    const reply = () => {
        let user = JSON.parse(localStorage.getItem("user"));
        let data = {
            ticketId: ticket.id,
            replyBy: user.id,
            text: newReply,
            replyByFullName: user.fullName,
        };
        HubCore.post(`${ticketsRoot}/replies/`, data).then((response) => {
            if (replies) setReplies([...replies, response.data]);
            else setReplies([response.data]);
            setNewReply("");
        });
    };

    const fetchBusiness = (id) => HubCore.get(`/businesses/${id}`).then((response) => setBusiness(response.data));

    const fetchOwner = (id) => HubCore.get(`/owners/${id}`).then((response) => setOwner(response.data));

    const closeTicket = () => {
        setLoading(true);
        HubCore({
            url: ticketUrl,
            method: "PATCH",
            data: { state: 3 },
        })
            .then((response) => {
                setTicket(response.data);
                history.push("/tickets");
            })
            .catch((error) => console.error(error))
            .then(() => setLoading(false));
    };

    const getTicketStateText = () => {
        if (!ticketState) return "...";
        if (ticketState.state === 0) return "مفتوحة";
        else if (ticketState.state === 1) return "مغلقة بواسطة مندوب";
        else if (ticketState.state === 2) return "مغلقة بواسطة المالك";
        else if (ticketState.state === 3) return "مغلقة بواسطة موظف";
    };

    const canClose = () => ticketState && ticketState.state != 3;

    const canDelete = () => localStorage.getItem("isSuper") === "true";

    const getRating = (rating) => {
        let value = rating?.value ?? 0;
        const rate = [1, 2, 3, 4, 5];
        return (
            <span>
                {rate.map((index) => (
                    <MaterialIcon icon="star" className={index <= value ? "text-yellow-400" : "text-gray-300"} key={index} />
                ))}
            </span>
        );
    };

    const getAudioPlayer = () => {
        let audioAttachment = attachments.find((x) => x.type === 2);
        if (!audioAttachment) return <></>;
        return (
            <audio autoPlay={false} preload="metadata" controls="controls">
                <source src={`https://tickets.morabaaapps.com${audioAttachment.url}`} type="audio/mpeg"></source>
            </audio>
        );
    };

    const getImages = () =>
        attachments
            .filter((attachment) => attachment.type === 0)
            .map((attachment) => (
                <a key={attachment.id} target="_blank" href={`https://tickets.morabaaapps.com${attachment.url}`}>
                    <img className="shadow-xl rounded" alt="attachment" src={`https://tickets.morabaaapps.com${attachment.url}`} />
                </a>
            ));

    const deleteTicket = () => HubCore.delete(ticketUrl).then(() => (window.location = "/tickets"));

    const deleteReply = (replyId) => HubCore.delete(`${ticketsRoot}/replies/${replyId}`).then(() => setReplies(replies.filter((x) => x.id !== replyId)));

    useEffect(() => {
        fetchTicket();
    }, []);

    useEffect(() => {
        if (!ticket) return;
        if (ticket.businessId !== undefined) fetchBusiness(ticket.businessId);
        if (ticket.openedBy !== undefined) fetchOwner(ticket.openedBy);
        if (ticket.id) fetchReplies();
    }, [ticket]);

    useEffect(() => setTicketState(ticketStates && ticketStates.length >= 1 ? ticketStates[0] : null), [ticketStates]);

    return loading ? (
        <LoaderComponent />
    ) : (
        <ColumnFlex>
            <p className="m-4">
                <span className="font-bold text-lg">{ticket.title}</span>
                <br />
                <span className="font-bold text-md">
                    <Link className="underline" to={`/businesses/${ticket.businessId}`}>
                        {business?.title}
                    </Link>{" "}
                    |{" "}
                    <Link to={`/owners/${ticket.openedBy}`} className="underline">
                        {owner?.firstName} {owner?.lastName}
                    </Link>
                </span>
                <br />
                <span>{getLocalDt(ticket.openedAt)}</span>
                <br />
                <span className="font-bold">{getTicketStateText()}</span>
            </p>
            {rating && getRating(rating)}
            <p className="font-bold text-lg text-gray-700 bg-gray-100 my-4 p-4 rounded shadow-xl">{ticket.text}</p>
            <div className="grid gap-2 grid-cols-1 md:grid-cols-3 lg:grid-cols-5 my-4">
                {getAudioPlayer()}
                {getImages()}
            </div>
            <div className="flex flex-row">
                {canClose() && (
                    <button
                        className="px-4 py-2 my-2 ml-2 rounded bg-red-700 text-lg text-gray-50 font-bold shadow-lg hover:shadow-sm hover:bg-red-800 self-start"
                        onClick={() => closeTicket()}>
                        إغلاق
                    </button>
                )}
                {canDelete() && (
                    <button
                        className="px-4 py-2 my-2 rounded bg-red-700 text-lg text-gray-50 font-bold shadow-lg hover:shadow-sm hover:bg-red-800 self-start"
                        onClick={() => deleteTicket()}>
                        حذف
                    </button>
                )}
            </div>
            <p className="font-bold text-gray-700 text-xl mt-4 mb-2">الردود</p>
            {replies &&
                replies.map((x) => (
                    <NeuCard key={x.id}>
                        <ColumnFlex>
                            <p className="p-3">
                                <span>{x.text}</span>
                                <br />
                                <br />
                                <span className="font-bold text-sm text-gray-500">- {x.replyByFullName}</span>
                                <br />
                                <span className="text-green-700 font-bold">{moment(x.createdAt).fromNow()}</span>
                            </p>
                            <button className="p-2 font-bold text-gray-50 bg-red-700 rounded self-start mb-2 mr-2" onClick={() => deleteReply(x.id)}>
                                حذف
                            </button>
                        </ColumnFlex>
                    </NeuCard>
                ))}
            <p className="font-bold text-gray-600 mt-4 mb-2">إضافة رد</p>
            <textarea className="shadow-xl rounded p-2" placeholder="نص الرد" value={newReply} onChange={(e) => setNewReply(e.target.value)} rows={5} />

            <button
                className="px-4 py-2 my-2 rounded bg-green-700 text-lg text-gray-50 font-bold shadow-lg hover:shadow-sm hover:bg-green-800 self-start"
                onClick={() => reply()}>
                إرسال
            </button>
        </ColumnFlex>
    );
};

export default TicketView;
