import LoaderComponent from "../../Components/LoaderComponent";
import GridComponent from "../../Components/GridComponent";
import ColumnFlex from "../../Components/ColumnFlex";
import React, { useEffect, useState } from "react";
import RowFlex from "../../Components/RowFlex";
import { useParams } from "react-router-dom";
import moment from "moment";
import { HubCore } from "../../api";
import "moment/locale/ar";

const BalancesView = () => {
    const params = useParams();
    const [credits, setcredits] = useState([]);
    const [loading, setLoading] = useState(true);

    const getDate = (date) => moment(date).fromNow();

    const fetchCreditLogs = () => {
        HubCore.get(`/owners/${params.id}/creditlogs/?reason=-1`)
            .then((response) => {
                setcredits(response.data);
            })
            .finally(() => setLoading(false));
    };
    useEffect(fetchCreditLogs, []);

    return loading ? (
        <LoaderComponent />
    ) : credits && credits.length > 0 ? (
        <GridComponent gap={6} mdCols={2} lgCols={3}>
            {credits.map((credit) => (
                <ColumnFlex className="shadow-lg border border-gray-300 rounded " key={credit.id}>
                    <RowFlex className="font-bold text-lg  p-2 leading-loose">
                        <p className="text-green-600 w-16">المبلغ :</p>
                        <span className="text-green-600 mx-2 px-2 rounded bg-green-200">{credit.amount + "$"}</span>
                    </RowFlex>
                    <RowFlex className="font-bold text-lg  p-2 leading-loose">
                        <p className="text-yellow-600 w-16">التاريخ :</p>
                        <span className="text-yellow-600 mx-2 px-2 rounded bg-yellow-200">{getDate(credit.happenedAt)}</span>
                    </RowFlex>
                    <p className="font-bold text-center text-lg p-2 rounded-b text-gray-800 bg-gray-300">{credit.note}</p>
                </ColumnFlex>
            ))}
        </GridComponent>
    ) : (
        <p className="font-bold text-center mt-4">...Nothing here</p>
    );
};

export default BalancesView;
