import React, { useEffect, useState } from "react";
import { HubCore } from "../../api";
import LoaderComponent from "../../Components/LoaderComponent";

const StatisticsView = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [statistics, setStatistics] = useState(null);

    const fetchStatistics = () =>
        HubCore.get("https://questionnairebe.morabaaapps.com/api/v1/responses/stats")
            .then((response) => setStatistics(response.data))
            .finally(() => setIsLoading(false));

    useEffect(() => fetchStatistics(), []);

    return isLoading ? (
        <LoaderComponent />
    ) : statistics ? (
        <div className="text-2xl text-gray-700">
            <p>
                <span>عدد الأجوبة الكلي: </span>
                <span className="font-bold text-gray-600">{statistics.totalResponses}</span>
            </p>
            {statistics.questionStatistics.map((x, i) => (
                <p className="mt-4" key={i}>
                    <span className="font-bold text-xl text-gray-700">{x.question}</span>
                    <br />
                    {Object.keys(x.countByChoice).map((y, iy) => (
                        <span key={iy} className="text-gray-600 font-bold text-lg">
                            {y}: {x.countByChoice[y]}
                            <br />
                        </span>
                    ))}
                </p>
            ))}
        </div>
    ) : (
        <></>
    );
};

export default StatisticsView;
