import React from "react";
import { Link } from "react-router-dom";

const LinkCard = (props) => {
    return (
        <Link 
            to={{ pathname: props.to, data: props.data }}
            className={props.className}
            // className={`bg-gray-50 shadow-lg hover:shadow-xl cursor-pointer rounded-md px-4 py-3 ${props.className}`}
        >
            {props.children}
        </Link>
    );
};

export default LinkCard;
