import React from "react";
import NewUser from "../Components/NewUser";

const NewStaff = ({ onSuccess, staff, onFailure }) => {
    const exitingData = staff
        ? {
              firstName: staff.firstName,
              lastName: staff.lastName,
              phoneNumber: staff.phoneNumber,
              id: staff.id,
          }
        : null;

    const title = staff ? "تعديل" : "إضافة";

    return <NewUser onSuccess={onSuccess} url="staff" title={title} exitingData={exitingData} />;
};

export default NewStaff;
