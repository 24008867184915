import React from "react";
import { Link } from "react-router-dom";

const SideNeuCardLink = (props) => {
    return (
        <Link to={{ pathname: props.link, data: props.data }} onClick={props.onClick}
            style={{
                color: props.onClick === true ? '#65b1c7' : '#525c65', background: props.onClick === true ? '#ecf0f3' : '#fff',
                alignContent: 'center', alignItems: 'center', justifyContent: 'center', borderColor: "#ecf0f3", borderWidth: '1px'
            }} className={`${props.className} h-full w-full bg-gray-50`} onClick={props.onClick}>
            {props.children}
        </Link>
    );
};

export default SideNeuCardLink;
