import React, { useEffect, useState } from "react";
import { HubCore } from "../../api";
import ColumnFlex from "../../Components/ColumnFlex";
import GridComponent from "../../Components/GridComponent";
import TicketCard from "../../Components/TicketCard";

const TicketsView = ({ businessId, ownerId, ...props }) => {
    const [tickets, setTickets] = useState([]);
    const [selectedState, setSelectedState] = useState(0);
    const [dtFrom, setDtFrom] = useState(new Date());
    const [dtTo, setDtTo] = useState(new Date());
    const [ownerName, setOwnerName] = useState("");
    const fetchTickets = () => {
        let url = "https://tickets.morabaaapps.com/api/v1/tickets/";
        url += businessId ? `ofbusiness/${businessId}/?` : "";
        url += ownerId ? `ofowner/${ownerId}/?` : "";
        url += !ownerId && !businessId ? "?" : "";
        if (selectedState > -1) url += `state=${selectedState}&`;
        if (!!dtFrom) url += `from=${dtFrom.toISOString().split("T")[0]}T00:00:00.0z&`;
        if (!!dtTo) url += `to=${dtTo.toISOString().split("T")[0]}T23:59:59.999999z&`;
        if (ownerName) url += `owner=${ownerName}`;
        HubCore.get(url).then((result) => {
            setTickets(result.data);
        });
    };
    useEffect(fetchTickets, []);

    return (
        <ColumnFlex>
            <GridComponent className="">
                <ColumnFlex>
                    <p className="font-bold text-lg text-gray-600">حالة التذكرة</p>
                    <select className="font-bold text-lg text-gray-600 px-4 py-1 mt-2" value={selectedState} onChange={(e) => setSelectedState(e.target.value)}>
                        <option value="0">مفتوحة</option>
                        <option value="2">اغلقها المالك</option>
                        <option value="3">اغلقها موظف</option>
                    </select>
                </ColumnFlex>
                <ColumnFlex className="mr-2">
                    <p className="font-bold text-lg text-gray-600 flex-1">من تاريخ</p>
                    <input type="datetime-local" value={dtFrom} onChange={(e) => setDtFrom(new Date(e.target.value))} className="px-4 py-2 mt-2" />
                </ColumnFlex>
                <ColumnFlex className="mr-2">
                    <p className="font-bold text-lg text-gray-600 flex-1">إلى تاريخ</p>
                    <input type="datetime-local" value={dtTo} onChange={(e) => setDtTo(new Date(e.target.value))} className="px-4 py-2 mt-2" />
                </ColumnFlex>
                {!businessId && !ownerId && (
                    <ColumnFlex className="mr-2">
                        <p className="font-bold text-lg text-gray-600 flex-1">إسم الزبون</p>
                        <input
                            type="text"
                            value={ownerName}
                            onChange={(e) => setOwnerName(e.target.value)}
                            className="px-4 py-2 mt-2 rounded"
                            placeholder="إسم الزبون"
                        />
                    </ColumnFlex>
                )}
                <button className="col-span-full px-4 py-2 rounded bg-green-700 text-gray-50 font-bold text-xl" onClick={fetchTickets}>
                    بحث
                </button>
            </GridComponent>
            <GridComponent lgCols="3">
                {tickets.map((ticket) => (
                    <TicketCard ticket={ticket} key={ticket.ticket.id} />
                ))}
            </GridComponent>
        </ColumnFlex>
    );
};

export default TicketsView;
