import React from "react";
import { Route, Redirect } from "react-router-dom";

const PermissionGuardedRoute = ({ component: Component, permissions, ...rest }) => {
    let token = localStorage.getItem("token");
    let authed = !!token;
    let user = JSON.parse(localStorage.getItem("user") ?? "");
    let isSuperStaff = (localStorage.getItem("isSuper") ?? "false") === "true";

    let canAccess =
        authed && (isSuperStaff || (user && user.permissions && user.permissions.map((x) => x.key).filter((x) => permissions.includes(x)).length > 0));

    return <Route {...rest} render={(props) => (canAccess === true ? <Component {...props} /> : <Redirect to="/login" />)} />;
};

export default PermissionGuardedRoute;
