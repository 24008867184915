import React from "react";

const NeuAnchor = (props) => {
    return (
        <a href={props.link} target="_blank" className={`neu-card ${props.className}`}>
            {props.children}
        </a>
    );
};

export default NeuAnchor;
