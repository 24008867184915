import React from "react";
import RowFlex from "./RowFlex";
import FormLabel from "./FormLabel";
import ColumnFlex from "./ColumnFlex";
import FormTextInput from "./FormTextInput";

const Form = (props) => {
    const submit = (e) => {
        e.preventDefault();
        props.onSubmit();
        return false;
    };

    return (
        <form onSubmit={submit} className={props.className}>
            <ColumnFlex>
                <FormLabel htmlFor={props.name} text={props.label || props.placeholder} className="mr-2" />
                <RowFlex>
                    <FormTextInput
                        name={props.name}
                        type={props.type}
                        value={props.value}
                        required={props.required}
                        placeholder={props.placeholder}
                        valueChanged={props.valueChanged}
                    />
                    <input
                        value={props.submitText || "بحث"}
                        type="submit"
                        className="bg-green-500 rounded text-white  text-lg font-bold px-4 mr-2 shadow-lg hover:shadow-md cursor-pointer"
                    />
                </RowFlex>
            </ColumnFlex>
        </form>
    );
};

export default Form;
