import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useHistory, useParams } from "react-router-dom";
import { HubCore } from "../../api";
import LoadreComponent from "../../Components/LoaderComponent";
import NeuCard from "../../Components/NeuCard";

const ResponseView = () => {
    let params = useParams();
    let history = useHistory();

    const [response, setResponse] = useState(null);

    const deleteResponse = () =>
        HubCore.delete(`https://questionnairebe.morabaaapps.com/api/v1/responses/${params.id}`).then(() => history.push("/questionnaire"));

    useEffect(() => {
        HubCore.get(`https://questionnairebe.morabaaapps.com/api/v1/responses/${params.id}`).then((apiResponse) => setResponse(apiResponse.data));
    }, []);

    return response ? (
        <div className="flex flex-col">
            <NeuCard key={response.id} className="p-4">
                <p className="font-bold text-gray-800">
                    <span className="text-2xl text-gray-600">{response.fullName}</span>
                    <br />
                    <span className="text-lg text-gray-700">
                        {response.businessTitle}
                        <br />
                        {response.phoneNumber}
                        <br />
                        {response.address}
                    </span>
                </p>
                <Moment className="text-green-700 font-bold" fromNow>
                    {response.respondedAt}
                </Moment>
            </NeuCard>
            <p className="mt-4 font-bold">
                {response.responses.map((x) => (
                    <span key={x.id}>
                        {x.text}
                        <br />
                        {x.choice}
                        <br />
                        <br />
                    </span>
                ))}
            </p>
            <button className="px-4 py-2 rounded shadow-lg cursor-pointer bg-red-700 text-gray-50 font-bold" onClick={() => deleteResponse()}>
                حذف
            </button>
        </div>
    ) : (
        <LoadreComponent />
    );
};

export default ResponseView;
