import React, { useEffect, useState } from "react";
import { HubCore } from "../../api";
import GridComponent from "../../Components/GridComponent";
import NeuCard from "../../Components/NeuCard";
import NewAgent from "../../Components/NewAgent";
import ColumnFlex from "../../Components/ColumnFlex";
import LoaderComponent from "../../Components/LoaderComponent";

const AgentsView = () => {
    const [agents, setAgents] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchAgents = () => {
        HubCore.get("/agents/").then((response) => {
            setAgents(response.data);
            setLoading(false);
        });
    };

    const newAgentAdded = (agent) => {
        setAgents([...agents, agent]);
    };

    useEffect(() => fetchAgents(), []);

    return loading ? (
        <LoaderComponent />
    ) : (
        <ColumnFlex>
            <NewAgent onSuccess={newAgentAdded} />
            <p className="font-bold text-2xl mt-8">الوكلاء</p>
            <GridComponent>
                {agents.map((agent) => (
                    <NeuCard link={`/agents/${agent.id}`} text={`${agent.firstName} ${agent.lastName}`} key={agent.id} />
                ))}
            </GridComponent>
        </ColumnFlex>
    );
};

export default AgentsView;
