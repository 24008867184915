import { HubCore } from "../api";
import FormInput from "./FormInput";
import ColumnFlex from "./ColumnFlex";
import FormSubmit from "./FormSubmit";
import LoaderComponent from "./LoaderComponent";
import React, { useState, useEffect } from "react";

const NewUser = (props) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.exitingData) {
            setFirstName(props.exitingData.firstName);
            setLastName(props.exitingData.lastName);
            setPhoneNumber(props.exitingData.phoneNumber);
        }
    }, []);

    const getRequestMethod = () => (props.exitingData ? "PATCH" : "POST");

    const getRequestUrl = () => {
        var url = `/${props.url}`;
        if (props.exitingData) url += `/${props.exitingData.id}/`;
        return url;
    };

    const appendAdditionalData = (data) => {
        if (props.additionalData) {
            Object.keys(props.additionalData).forEach((key) => {
                data[key] = props.additionalData[key];
            });
        }
        return data;
    };

    const buildRequestData = () => {
        let data = {
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
            password: password,
            passwordConfirm: passwordConfirm,
        };
        return data;
    };

    const getRequestData = () => {
        let data = buildRequestData();
        return appendAdditionalData(data);
    };

    const clearInputData = () => {
        setFirstName("");
        setLastName("");
        setPhoneNumber("");
        setPassword("");
        setPasswordConfirm("");
    };

    const submitForm = (e) => {
        e.preventDefault();
        if (loading) return false;
        let data = getRequestData();
        setLoading(true);
        HubCore({
            method: getRequestMethod(),
            url: getRequestUrl(),
            data: data,
        })
            .then((response) => {
                if (props.exitingData) props.onSuccess(false);
                else {
                    props.onSuccess(response.data);
                    clearInputData(response.data);
                }
            })
            .catch((error) => {
                if (props.onFailure) props.onFailure(error);
            })
            .finally(() => setLoading(false));
        return false;
    };

    return loading ? (
        <LoaderComponent />
    ) : (
        <form onSubmit={submitForm}>
            <ColumnFlex>
                <p className="font-bold text-xl">{props.title || "New User"}</p>
                <FormInput name="first-name" placeholder="الإسم الاول" value={firstName} valueChanged={(e) => setFirstName(e.target.value)} required={true} />
                <FormInput name="last-name" placeholder="الإسم الاخير" value={lastName} valueChanged={(e) => setLastName(e.target.value)} required={true} />
                <FormInput
                    name="phonenumber"
                    placeholder="رقم الهاتف"
                    type={"tel"}
                    value={phoneNumber}
                    valueChanged={(e) => setPhoneNumber(e.target.value)}
                    required={true}
                />
                <FormInput
                    name="password"
                    placeholder="كلمة السر"
                    value={password}
                    valueChanged={(e) => setPassword(e.target.value)}
                    type={"password"}
                    required={true}
                />
                <FormInput
                    name="password-confirm"
                    placeholder="تأكيد كلمة السر"
                    value={passwordConfirm}
                    valueChanged={(e) => setPasswordConfirm(e.target.value)}
                    type={"password"}
                    required={true}
                />
                {props.children}
                <FormSubmit />
            </ColumnFlex>
        </form>
    );
};

export default NewUser;
