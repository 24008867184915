import { HubCore } from "../api";
import RowFlex from "./RowFlex";
import ColumnFlex from "./ColumnFlex";
import React, { useState } from "react";

const GuestUploadLinkGenerator = () => {
    const [generatedLink, setGeneratedLink] = useState("");

    const generateLink = () => HubCore.get("/files/generate").then((response) => setGeneratedLink(`https://hub.morabaaapps.com/upload/${response.data.token}`));

    const copyToClipboard = () => {
        const el = document.createElement("textarea");
        el.value = generatedLink;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
    };

    return (
        <ColumnFlex>
            <button
                className="px-4 py-2 bg-gray-50 text-purple-800 rounded shadow-md hover:shadow-lg hover:text-gray-50 hover:bg-purple-800"
                onClick={(e) => generateLink()}>
                توليد رابط
            </button>
            {generatedLink ? (
                <RowFlex className="my-2 items-center">
                    <button
                        onClick={copyToClipboard}
                        className="ml-2 px-4 py-2 bg-gray-50 text-purple-800 rounded shadow-md hover:shadow-lg hover:bg-yellow-300 hover:font-bold hover:flex-grow">
                        نسخ
                    </button>
                    <a
                        href={generatedLink}
                        className="px-2 my-2 flex-grow text-purple-800 bg-purple-100 rounded shadow-md hover:shadow-lg hover:text-purple-100 hover:bg-purple-800 text-center py-2 cursor-pointer overflow-x-scroll"
                        target="_blank">
                        {generatedLink}
                    </a>
                </RowFlex>
            ) : (
                <></>
            )}
        </ColumnFlex>
    );
};

export default GuestUploadLinkGenerator;
