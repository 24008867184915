import { HubCore, updateToken } from "../../api";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import FormInput from "../../Components/FormInput";
import FormSubmit from "../../Components/FormSubmit";
import { writeStorage } from "@rehooks/local-storage";
import LoaderComponent from "../../Components/LoaderComponent";

const LoginView = () => {
    let history = useHistory();
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const getStaff = () =>
        HubCore.get("/staff/me/").then((response) => {
            writeStorage("user", response.data);
            setIsLoading(false);
            history.push("/");
        });

    const login = (e) => {
        e.preventDefault();
        setIsLoading(true);
        let data = {
            phone_number: phone,
            password: password,
        };
        HubCore.post("/staff/login", data)
            .then((response) => {
                HubCore.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
                writeStorage("token", response.data.token);
                getStaff();
                updateToken(response.data.token);
            })
            .catch((err) => setIsLoading(false));
        return false;
    };

    return isLoading ? (
        <LoaderComponent />
    ) : (
        <form className="flex flex-col px-4 mx-auto w-full lg:w-1/2" onSubmit={login}>
            <FormInput name="phone" placeholder="رقم الهاتف" value={phone} type={"tel"} valueChanged={(e) => setPhone(e.target.value)} required={"required"} />
            <FormInput
                name="password"
                placeholder="كلمة السر"
                value={password}
                valueChanged={(e) => setPassword(e.target.value)}
                type={"password"}
                required={"required"}
            />
            <FormSubmit value="تسجيل الدخول" />
        </form>
    );
};

export default LoginView;
